import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Typography } from "antd";
import { useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import {
  AssetKeyType,
  defaultFileTypes,
  MediaLibraryTabs,
  MediaType,
  SelectedImageContext,
  ServerFolderMedia,
  UploadFileSource,
  VideoAction,
  VideoScaleMethod
} from "app/types/media";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { trackEvent } from "app/store/thunks/analyticsEvents.thunk";
import {
  BrandedMediaAsset,
  BrandKit,
  Dimensions,
  Draft,
  FeatureFlag,
  FitOptions,
  LayoutAsset,
  LayoutCategory,
  MediaAssetProperties,
  MediaCategory,
  MimeType,
  PatchOperation,
  VideoAdjustment
} from "app/types";
import { scenesActions } from "app/store/slices/scenes.slice";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import {
  cropImageDimensions,
  fetchingStatus,
  fileStackFetchImageSizePromise,
  fileStackFetchMetaPromise,
  onImageError,
  onVideoError
} from "app/utils/helpers";
import MediaLibraryModalHeaderActionButtons from "app/pages/mediaLibrary/MediaLibraryModalHeaderActionButtons";
import MediaLibraryModalTabs from "app/pages/mediaLibrary/MediaLibraryModalTabs";
import ConditionalRender from "app/components/common/ConditionalRender";
import { getFilteredSortedMedia } from "app/store/selectorsV2/media.selectors";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { RootState } from "app/store/store";

import { SMALL_SCREEN_PX } from "app/config/Constants";
import { brandKitActions } from "app/store/slices/brandKit.slice";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import usePresentationToDraft from "app/components/editor/presentations/usePresentationToDraft";
import useNotifications from "app/hooks/useNotifications";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import "./mediaLibrary.scss";
import MediaLibraryPreviewModal from "app/pages/mediaLibrary/mediaLibraryPreviewModal";
import { extractFilestackHandle, getThumbnailUrl } from "app/services/filestackClient";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledImage = styled.img`
  object-fit: contain;
  height: 70px;
  width: 70px;
  border-radius: 10px;
`;

const StyledVideo = styled.video`
  object-fit: contain;
  height: 70px;
  width: 70px;
  border-radius: 10px;
`;

/** This is a FlexRow and not Text because the Unsplash title contains html selectors which messed up in the Text Component */
const TitleFlexRow = styled(H1_FlexRow)`
  font-family: Poppins;
  font-size: 13px;
  line-height: 22px;
  color: ${(props) => props.theme.gray8};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 300px;
  margin: auto 0;
`;

const ModalFooter = styled(H1_FlexRow)`
  border-top: 3px solid ${(props) => props.theme.gray3};
  margin-top: 3px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

const AlignSelfFlexRow = styled(H1_FlexRow)`
  align-self: flex-end;
`;

const TopWrappeRow = styled(H1_FlexRow)`
  z-index: 2;
  top: 10px;
`;

const TabsWrapper = styled(H1_FlexColumn)`
  padding-bottom: 30px;
`;

const MediaLibraryModal = () => {
  const [previewModalUrl, setPreviewModalUrl] = useState<string>("");
  const [isPreviewModalVideo, setIsPreviewModalVideo] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<MediaLibraryTabs>(MediaLibraryTabs.MyMedia);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState<SelectedImageContext>();
  const [selectedUrl, setSelectedUrl] = useState<string>();
  const [selectedMediaType, setSelectedMediaType] = useState<MediaType>();
  const [selectedMediaId, setSelectedMediaId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<{ url: string; type: MediaType }>();
  const [previewTitle, setPreviewTitle] = useState<string>("");

  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen } = useModal();
  const { notifyMessages } = useNotifications();

  const intl = useIntl();
  const theme = useTheme();
  const onSucceedUploading = () => {
    if (activeTab !== MediaLibraryTabs.MyMedia) {
      setActiveTab(MediaLibraryTabs.MyMedia);
    }
  };

  const {
    executeImport,
    importLoading,
    progress: progressPresentation,
    storeUrl,
    uploadedMedia,
    clearUserUploadedMedia,
    resetUploader
  } = useMediaLibraryUploader(onSucceedUploading);
  const {
    onSelectFile: onSelectPresentationFile,
    progress,
    importLoading: presentationLoading
  } = usePresentationToDraft({
    draftCreate: false,
    autoCreate: true,
    autoImport: true
  });
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const fileList: ServerFolderMedia[] = useAppSelector(getFilteredSortedMedia);
  const filestackPolicy = useAppSelector((state: RootState) => state.media.filestackReadPolicy);
  const draft: Draft = useAppSelector((state: RootState) => state.drafts.currentDraft);
  const currentBrandkit = useAppSelector((state: RootState) => state.brandKit.currentBrandkit);
  const mediaStatus = useAppSelector((state: RootState) => state.media.mediaStatus);
  const createMediaStatus = useAppSelector((state: RootState) => state.media.createMediaStatus);
  const mediaCreateLoading = createMediaStatus === fetchingStatus.loading;
  const draftId = draft?.id as string;

  const attributeType: MediaCategory = editorModalOpen?.context?.attributeType || "media";
  const assetKeyType: AssetKeyType = editorModalOpen?.context?.assetKeyType;
  const mediaType: MediaType | undefined = editorModalOpen?.context?.mediaType;
  const hideStockVideos: boolean | undefined = editorModalOpen?.context?.hideStockVideos;
  const mainMode: boolean | undefined = editorModalOpen?.context?.mainMode;

  const assetKey = editorModalOpen?.context?.assetKey;
  const afterChanged = editorModalOpen?.context?.afterChanged;

  const currentLayoutMedia: LayoutAsset | undefined =
    assetKeyType === AssetKeyType.scene
      ? (scene?.layout?.assets[attributeType as LayoutCategory] as LayoutAsset[])?.find(
          (theMedia: LayoutAsset) => theMedia.key === assetKey
        )
      : undefined;

  const draftMediaAssetProperties = draft?.attributes?.[attributeType]?.[assetKey] as
    | MediaAssetProperties
    | undefined;
  const sceneMediaAssetProperties = scene?.attributes?.[attributeType]?.[assetKey] as
    | MediaAssetProperties
    | undefined;

  const { currentSelectedValue, currentSelectedMediaId } = (() => {
    switch (assetKeyType) {
      case AssetKeyType.draft:
        return {
          currentSelectedValue: draftMediaAssetProperties?.url,
          currentSelectedMediaId: draftMediaAssetProperties?.media_id
        };
      case AssetKeyType.brandkit:
        return {
          currentSelectedValue: (currentBrandkit[assetKey as keyof BrandKit] as BrandedMediaAsset)
            ?.url,
          currentSelectedMediaId: (currentBrandkit[assetKey as keyof BrandKit] as BrandedMediaAsset)
            ?.media_id
        };
      default:
        return {
          currentSelectedValue: sceneMediaAssetProperties?.url,
          currentSelectedMediaId: sceneMediaAssetProperties?.media_id
        };
    }
  })();
  const visible = editorModalOpen?.open === ModalName.mediaLibraryModal;
  const isImageFitDisplayed =
    flags[FeatureFlag.imageFit] && currentLayoutMedia?.restrictions?.ratio_w;

  useEffect(() => {
    if (visible) {
      dispatch(mediaActions.getMediaListRequest());
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    (async () => {
      if (selectedPreviewImage) {
        if (
          selectedPreviewImage.tab === MediaLibraryTabs.MyMedia ||
          selectedPreviewImage.tab === MediaLibraryTabs.Hourone ||
          selectedPreviewImage.tab === MediaLibraryTabs.StableDiffusion
        ) {
          const [{ type, url }, metadata] = await Promise.all([
            getThumbnailUrl(
              selectedPreviewImage.handle,
              selectedPreviewImage.mediaType === MediaType.video,
              filestackPolicy
            ),
            fileStackFetchMetaPromise(selectedPreviewImage.url as string, filestackPolicy)
          ]);
          setPreviewTitle(metadata.filename);
          setThumbnailUrl({ type: type as MediaType, url });
        } else {
          setThumbnailUrl({
            type: selectedPreviewImage.thumbnailType as MediaType,
            url: selectedPreviewImage.thumbnail as string
          });
          setPreviewTitle(selectedPreviewImage.title as string);
        }
      }
    })();
  }, [selectedPreviewImage]);

  useEffect(() => {
    if (mediaStatus === fetchingStatus.succeeded) {
      dispatch(mediaActions.updateMediaStatusToIdle());
    }
    if (mediaStatus === fetchingStatus.failed) {
      dispatch(mediaActions.updateMediaStatusToIdle());
    }
  }, [mediaStatus]);

  useEffect(() => {
    if (visible) {
      const recentSelectedUrl = editorModalOpen?.context?.selectedUrl;
      if (recentSelectedUrl) {
        setSelectedUrl(recentSelectedUrl);
        setSelectedMediaId(undefined);
      } else if (currentSelectedValue) {
        setSelectedUrl(currentSelectedValue);
        // todo investigaete whate we do with the media
        // todo investigate the choose image flow.
        setSelectedMediaId(currentSelectedMediaId);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (selectedUrl && !isLoaded && visible) {
      setIsLoaded(true);
      if (selectedUrl.indexOf("unsplash") > -1) {
        setActiveTab(MediaLibraryTabs.Unsplash);
      } else if (selectedUrl.indexOf("pexels") > -1) {
        setActiveTab(MediaLibraryTabs.Pexels);
      } else {
        setActiveTab(MediaLibraryTabs.MyMedia);
      }
    }
  }, [selectedUrl, visible]);

  useEffect(() => {
    const fileInfo = fileList.find((f) => f.data?.url === selectedUrl);
    if (visible && fileInfo && fileInfo.data?.source === "upload" && !selectedPreviewImage) {
      setSelectedPreviewImage({
        url: fileInfo.data?.url as string,
        title: fileInfo.data?.name,
        handle: extractFilestackHandle(fileInfo.data?.url as string),
        thumbnailType: MediaType.image,
        mediaId: fileInfo.data?.id as string,
        tab: MediaLibraryTabs.MyMedia
      });
    }
  }, [fileList.length, selectedUrl]);

  useEffect(() => {
    if (uploadedMedia) {
      (async () => {
        if (uploadedMedia.source === UploadFileSource.thirdParty) {
          const handle = uploadedMedia.url.split("/").pop() as string;
          const dimensions: Dimensions | undefined = await getImageWidthAndHeight(handle);
          let value: any = {
            url: uploadedMedia.url,
            modification: dimensions
              ? {
                  fit: FitOptions.smart_crop,
                  width: dimensions.width as number,
                  height: dimensions.height as number
                }
              : undefined
          };

          if (attributeType === "visual") {
            value = {
              ...value,
              preset_override: {
                ...scene?.attributes?.visual?.[assetKey]?.preset_override,
                media_url: uploadedMedia.url,
                type: "media"
              }
            };
          }

          const calculatedVideoAdjustment = calculateAutoVideoPadding();
          if (calculatedVideoAdjustment) {
            value = {
              ...value,
              video_adjustments: calculatedVideoAdjustment
            };
          }

          const operations: PatchOperation[] = [
            {
              op: "replace",
              path: `attributes.${attributeType}.${assetKey}`,
              value
            }
          ];

          patchOperations(operations);
          resetUploader();
          setIsLoading(false);
          onCloseModal(true);
        }
        if (uploadedMedia.source === UploadFileSource.mediaLibrary && fileList.length > 0) {
          const fileInfo = fileList.find((f) => f.data?.url === uploadedMedia.url);
          if (fileInfo) {
            onImageSelect(uploadedMedia.url, {
              handle: extractFilestackHandle(fileInfo.data?.url as string),
              thumbnailType: MediaType.image,
              url: fileInfo.data?.url as string,
              thumbnail: uploadedMedia.url,
              title: fileInfo.data?.name,
              tab: MediaLibraryTabs.MyMedia,
              mediaType: fileInfo.data?.media_type as MediaType,
              mediaId: fileInfo?.data?.id
            });
            clearUserUploadedMedia();
          }
        }
      })();
    }
  }, [uploadedMedia, selectedMediaType, fileList.length]);

  const onCloseModal = (isImageSelected?: boolean) => {
    if (!isImageSelected) {
      dispatch(
        analyticsEvents.closeMediaLibrary({
          selectedScene: {
            name: scene?.name as string,
            id: sceneId,
            index: selectedSceneIndex
          }
        })
      );
    }
    closeModal();
    setIsLoaded(false);
    dispatch(mediaActions.resetMediaLibrary());
    setSelectedPreviewImage(undefined);
    setSelectedUrl("");
    setThumbnailUrl(undefined);
    setPreviewTitle("");
    if (selectedPreviewImage?.tab) {
      setActiveTab(selectedPreviewImage.tab);
    }
  };

  const calculateAutoVideoPadding = () => {
    const isImage = selectedMediaType === MediaType.image;
    if (!isImage && assetKeyType !== AssetKeyType.draft) {
      const ratioW = currentLayoutMedia?.restrictions?.ratio_w as number;
      const ratioH = currentLayoutMedia?.restrictions?.ratio_h as number;
      const isLandscape = ratioW > ratioH;
      const ratio = ratioW / ratioH;
      const FULL_HD_WIDTH = 1920;
      const FULL_HD_HEIGHT = 1080;
      const scaleHeight = isLandscape ? FULL_HD_WIDTH / ratio : FULL_HD_HEIGHT;
      const roundHeight = Math.round(scaleHeight as number);
      const evenHeight = roundHeight % 2 === 0 ? roundHeight : roundHeight + 1;
      const scaleWidth = isLandscape ? FULL_HD_WIDTH : FULL_HD_HEIGHT * ratio;
      const roundWidth = Math.round(scaleWidth as number);
      const evenWidth = roundWidth % 2 === 0 ? roundWidth : roundWidth + 1;
      const videoAdjustments: VideoAdjustment[] = [
        {
          action: VideoAction.PAD_FIT,
          method: VideoScaleMethod.COLOR,
          color_key: "A1",
          width: evenWidth,
          height: evenHeight
        }
      ];

      return videoAdjustments;
    }
    return undefined;
  };

  const onFinish = async () => {
    setIsLoading(true);
    if (selectedUrl?.indexOf("filestack") === -1) {
      await storeUrl(selectedUrl);
      return;
    }
    const handle = selectedUrl?.split("/").pop() as string;
    const dimensions: Dimensions | undefined = await getImageWidthAndHeight(handle);
    let value: any = {
      media_id: selectedMediaId,
      url: selectedUrl,
      modification: dimensions
        ? {
            fit: FitOptions.smart_crop,
            width: dimensions.width as number,
            height: dimensions.height as number
          }
        : undefined
    };
    const calculatedVideoAdjustment = calculateAutoVideoPadding();
    if (calculatedVideoAdjustment) {
      value = {
        ...value,
        video_adjustments: calculatedVideoAdjustment
      };
    }

    if (attributeType === "visual") {
      value = {
        ...value,
        preset_override: {
          ...scene?.attributes?.visual?.[assetKey]?.preset_override,
          media_url: selectedUrl,
          type: "media"
        }
      };
    }

    let operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.${attributeType}.${assetKey}`,
        value
      }
    ];

    if (selectedUrl) {
      dispatch(
        analyticsEvents.changeMedia({
          selectedScene: {
            name: scene?.name as string,
            id: sceneId,
            index: selectedSceneIndex
          },
          media: {
            url: selectedUrl
          }
        })
      );
    } else {
      operations = [{ op: "delete", path: `attributes.${attributeType}.${assetKey}` }];
    }

    patchOperations(operations);
    setIsLoading(false);
    onCloseModal(true);
  };

  const getImageWidthAndHeight = async (handle: string): Promise<Dimensions | undefined> => {
    const isImage = selectedMediaType === MediaType.image;
    if (isImage && isImageFitDisplayed) {
      const dimensions: Dimensions = await fileStackFetchImageSizePromise(handle, filestackPolicy);
      const aspectRatio =
        (currentLayoutMedia?.restrictions?.ratio_w || 1) /
        (currentLayoutMedia?.restrictions?.ratio_h || 1);
      const { width, height } = cropImageDimensions(
        dimensions.width,
        dimensions.height,
        aspectRatio
      );
      return { width, height };
    }
    return undefined;
  };

  const patchOperations = (operations: PatchOperation[]) => {
    // todo is it realy can by more than one operations?
    if (assetKeyType === AssetKeyType.brandkit) {
      dispatch(brandKitActions.upsertBrandkitRequest({ [assetKey]: operations[0].value || null }));
    } else if (assetKeyType === AssetKeyType.draft) {
      dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId,
          operations
        })
      );
    } else {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations
        })
      );
    }
    if (afterChanged) {
      operations.forEach((op) => {
        afterChanged(op.path, op.value);
      });
    }
  };

  const onZoomItem = (event: React.MouseEvent, url: string, mediaType?: MediaType) => {
    event.stopPropagation();
    const isVideo = mediaType?.includes("video");
    dispatch(
      analyticsEvents.previewMedia({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        media: {
          url
        }
      })
    );
    setPreviewModalUrl(url);
    setIsPreviewModalVideo(!!isVideo);
    setIsPreviewModalOpen(true);
  };

  const onTabChange = (value: string) => {
    const updatedActiveTab = value as MediaLibraryTabs;
    dispatch(
      analyticsEvents.changeTabMediaLibrary({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        value: updatedActiveTab
      })
    );
    setActiveTab(updatedActiveTab);
  };
  const onImageSelect = (value: string, context?: SelectedImageContext) => {
    if (value) {
      setSelectedUrl(value);
      setSelectedMediaType(context?.mediaType);
      setSelectedMediaId(context?.mediaId);
      setSelectedPreviewImage(context);
      dispatch(
        trackEvent({
          eventName: "app:editor_mediaSelect",
          properties: {
            scene: sceneId,
            ...context,
            url: value
          }
        })
      );
    } else {
      setSelectedUrl(value);
      setSelectedMediaType(undefined);
      setSelectedMediaId(undefined);
      setSelectedPreviewImage(undefined);
      setThumbnailUrl(undefined);
      setPreviewTitle("");
    }
  };

  const findSelectedImage = () => {
    const imageElement = document.querySelector(
      `[data-image-handle="${selectedPreviewImage?.handle}"]`
    );
    if (imageElement) {
      imageElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSelectFile = (files: File[]) => {
    const presentations = files.filter((file) => MimeType.presentations.includes(file.type));
    const non_presentations = files.filter((file) => !MimeType.presentations.includes(file.type));
    if (presentations.length === 1) {
      if (onSelectPresentationFile(presentations[0])) {
        notifyMessages([
          {
            message: intl.formatMessage(mediaLibraryMessages.convertPresentationNotification)
          }
        ]);
      }
    }
    if (presentations.length > 1) {
      notifyMessages([
        {
          message: intl.formatMessage(mediaLibraryMessages.onePresentationAtTimeNotification),
          type: "error"
        }
      ]);
      return;
    }
    executeImport(non_presentations);
  };

  return (
    <StyledModal
      width="968px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "754px" }}
      onCancel={() => onCloseModal()}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="30px 0 0"
      $alignItems="center"
    >
      <MediaLibraryPreviewModal
        visible={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        isVideo={isPreviewModalVideo}
        url={previewModalUrl}
      />
      <TopWrappeRow
        flex="0 0 auto"
        width="100%"
        position="relative"
        justify="space-between"
        padding="0 30px"
      >
        <H1_FlexRow flex="0 0 auto">
          <H1_TextMidHeadline color={theme.gray9}>
            {intl.formatMessage(mediaLibraryMessages.headerTitle)}
          </H1_TextMidHeadline>
        </H1_FlexRow>
        <H1_FlexRow flex="0 0 auto">
          <MediaLibraryModalHeaderActionButtons
            executeImport={onSelectFile}
            importLoading={importLoading || presentationLoading || mediaCreateLoading}
            assetKey={assetKey}
            attributeType={attributeType}
          />
        </H1_FlexRow>
      </TopWrappeRow>
      <TabsWrapper width="100%" flex="1 1 auto" overflow="hidden">
        <MediaLibraryModalTabs
          mediaModalOpen={visible}
          activeTab={activeTab}
          onZoomItem={onZoomItem}
          selectedUrl={selectedUrl}
          onImageSelect={onImageSelect}
          onTabChange={onTabChange}
          progress={progress || progressPresentation}
          importLoading={importLoading || presentationLoading || mediaCreateLoading}
          mimetypes={
            mediaType === MediaType.image
              ? [MimeType.allImages]
              : ([defaultFileTypes] as MimeType[])
          }
          hideStockVideos={hideStockVideos}
          onSelectFile={onSelectFile}
          mainMode={mainMode}
        />
        <ConditionalRender condition={!mainMode}>
          <ModalFooter
            justify="space-between"
            align="items-center"
            flex="0 0 auto"
            width="100%"
            padding="20px 30px 0"
          >
            <H1_FlexRow flex="1 0 auto">
              <H1_FlexRow height="70px" gap="10px" onClick={findSelectedImage}>
                <ConditionalRender condition={thumbnailUrl?.type === MediaType.image}>
                  <StyledImage src={thumbnailUrl?.url} onError={onImageError} />
                </ConditionalRender>
                <ConditionalRender condition={thumbnailUrl?.type === MediaType.video}>
                  <StyledVideo src={thumbnailUrl?.url as string} onError={onVideoError} />
                </ConditionalRender>
                <TitleFlexRow align="center">
                  <Typography.Text ellipsis> {previewTitle} </Typography.Text>
                </TitleFlexRow>
              </H1_FlexRow>
            </H1_FlexRow>
            <AlignSelfFlexRow align="center" flex="0 0 auto" height="30px" gap="10px">
              <Button onClick={() => onCloseModal()} variant="light">
                {intl.formatMessage(mediaLibraryMessages.cancelButton)}
              </Button>
              <Button
                color="primary"
                isDisabled={!selectedPreviewImage?.url || isLoading}
                onClick={onFinish}
                isLoading={isLoading}
              >
                {intl.formatMessage(mediaLibraryMessages.selectButton)}
              </Button>
            </AlignSelfFlexRow>
          </ModalFooter>
        </ConditionalRender>
      </TabsWrapper>
    </StyledModal>
  );
};

export default MediaLibraryModal;
