import { createAsyncThunk } from "@reduxjs/toolkit";
import { CGNType } from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { getPlayback } from "app/services/serviceV2Apis";

const prefix = "[CGN]";

const getCGNRequest = createAsyncThunk<{ playback_id: string; type: CGNType }, void>(
  `${prefix} getCGNRequest`,
  async () => {
    const res = await getPlayback();
    return res;
  },
  thunkOptions
);

export default {
  getCGNRequest
};
