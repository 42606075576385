/* eslint-disable camelcase */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDraftScene,
  deleteDraftScene,
  duplicateScene,
  framePreview,
  generateTranscript,
  getDraftScenes,
  getSceneById,
  moveSceneRequest,
  patchDraft,
  patchScene,
  patchSceneLayout,
  updateSceneWithAugmentedText
} from "app/services/serviceV2Apis";
import {
  FramePreviewQuality,
  PartialScene,
  PatchOperation,
  Scene,
  Template,
  VoiceSpeechAudioAction
} from "app/types";
import { ThunkApi, thunkOptions } from "app/store/thunks/thunkCommon";
import { FeatureFlag } from "app/types/featureFlags";
import { scenesActions } from "app/store/slices/scenes.slice";
import { validateCreateVideo } from "app/components/editor/validations";
import UiActions from "app/store/actions/ui.actions";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import {
  AugmentedModification,
  AugmentedSelection,
  AugmentedType,
  GeneratedTranscriptResponse,
  GenerateTranscript,
  GenerateTranscriptType,
  PromptTone
} from "app/types/services";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { checkUrlForImg, fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";

const prefix = "[Scenes]";

const patchSceneRequest = createAsyncThunk<
  any,
  { draftId: string; sceneId: string; operations: PatchOperation[]; skipRePreview?: boolean },
  ThunkApi
>(
  `${prefix} patchSceneRequest`,
  async ({ draftId, sceneId, operations, skipRePreview }, thunkApi) => {
    const {
      user: { featureFlags },
      drafts: { currentDraft },
      templates: { currentTemplate },
      // @ts-ignore handels userUI store typing
      userUi: { intl, sceneIdsError: originalErrors },
      scenes: { deleteSceneStatus }
    } = thunkApi.getState();
    if (deleteSceneStatus === fetchingStatus.loading) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    const scenes = scenesGlobalSelectors.selectAll(thunkApi.getState());
    const limits = getLimits(thunkApi.getState());

    if (Object.keys(originalErrors).length) {
      const { sceneIdsErrors } = validateCreateVideo(
        currentDraft,
        scenes,
        currentTemplate as Template,
        intl,
        limits
      );

      thunkApi.dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }

    const isOnlyTranscriptChange = operations.every((operation) =>
      operation.path.includes("transcript")
    );
    const gotGenerate = operations.some((operation) => operation.op === "generate");
    const result = await patchScene(sceneId, operations);

    const atLeastOneAttributeChange = operations.some((operation) =>
      operation.path.startsWith("attributes.")
    );

    if (gotGenerate) {
      const allGenerativePaths = operations
        .filter((operation) => operation.op === "generate")
        .map((genOp) => genOp.path);

      thunkApi.dispatch(
        getSceneByIdRequest({
          sceneId,
          isGenerateRebound: true,
          paths: allGenerativePaths
        })
      );
    }

    if (
      !skipRePreview &&
      featureFlags[FeatureFlag.framePreviewFeature] &&
      !isOnlyTranscriptChange &&
      atLeastOneAttributeChange
    ) {
      thunkApi.dispatch(scenePreviewRequest({ draftId, sceneIds: [sceneId] }));
    }
    return result;
  },
  thunkOptions
);

const getSceneByIdRequest = createAsyncThunk<
  Scene[],
  { sceneId: string; isGenerateRebound?: boolean; paths?: string[] },
  ThunkApi
>(
  `${prefix} getSceneByIdRequest`,
  async ({ sceneId }) => {
    const result = await getSceneById(sceneId);
    return result;
  },
  thunkOptions
);

const scenePreviewRequest = createAsyncThunk<
  { scene_id: string; url: string; order_id: string; cached: boolean }[],
  { draftId: string; sceneIds: string[]; quality?: FramePreviewQuality },
  ThunkApi
>(
  `${prefix} scenePreviewRequest`,
  async ({ draftId, sceneIds, quality }, thunkAPI) => {
    let imageQuality: FramePreviewQuality | undefined = quality;
    if (!imageQuality) {
      imageQuality =
        (thunkAPI.getState().user.featureFlags.framePreviewQuality as FramePreviewQuality) ||
        FramePreviewQuality.low;
    }
    const result = await framePreview(draftId, sceneIds, imageQuality);
    return result;
  },
  thunkOptions
);

const moveScene = createAsyncThunk<
  any,
  {
    originalSceneIndex: number;
    originalParentIndex: number;
    draftId: string;
    sceneId: string;
    parentId?: string;
  },
  ThunkApi
>(
  `${prefix} moveSceneRequest`,
  async ({ draftId, sceneId, parentId }) => {
    const result = await moveSceneRequest(draftId, sceneId, parentId);
    return result;
  },
  thunkOptions
);

const getDraftsScenesRequest = createAsyncThunk<any, string, ThunkApi>(
  `${prefix} getDraftsScenesRequest`,
  async (draftId, thunkApi) => {
    const {
      user: { featureFlags }
    } = thunkApi.getState();
    const result = await getDraftScenes(draftId);

    if (featureFlags[FeatureFlag.framePreviewFeature]) {
      const urlsChecks = await Promise.all(
        result.map((scene) => scene.last_preview_url && checkUrlForImg(scene.last_preview_url))
      );
      const scenesIdsForPreview = result
        .filter((scene, index) => !urlsChecks[index])
        .map((scene) => scene.id);
      if (scenesIdsForPreview.length) {
        thunkApi.dispatch(scenePreviewRequest({ draftId, sceneIds: scenesIdsForPreview }));
      }
    }

    return result;
  },
  thunkOptions
);

const addDraftSceneRequest = createAsyncThunk<
  any,
  { draftId: string; parentId?: string; scene: PartialScene },
  ThunkApi
>(
  `${prefix} addDraftSceneRequest`,
  async ({ draftId, parentId, scene }, thunkApi) => {
    const result = await addDraftScene(draftId, scene, parentId);
    const {
      user: { featureFlags }
    } = thunkApi.getState();
    if (featureFlags[FeatureFlag.framePreviewFeature]) {
      thunkApi.dispatch(scenePreviewRequest({ draftId, sceneIds: [result.id] }));
    }
    return result;
  },
  thunkOptions
);

const duplicateSceneRequest = createAsyncThunk<
  Scene,
  { draftId: string; sceneId: string; parentId: string },
  ThunkApi
>(
  `${prefix} duplicateSceneRequest`,
  async ({ draftId, sceneId, parentId }) => {
    const result = await duplicateScene(draftId, sceneId, parentId);
    return result;
  },
  thunkOptions
);

const deleteDraftSceneRequest = createAsyncThunk<
  any,
  { draftId: string; sceneId: string },
  ThunkApi
>(
  `${prefix} deleteDraftSceneRequest`,
  async ({ draftId, sceneId }, thunkAPI) => {
    const { patchScenesStatus } = thunkAPI.getState().scenes;
    if (patchScenesStatus === fetchingStatus.loading) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    const result = await deleteDraftScene(draftId, sceneId);
    return result;
  },
  thunkOptions
);

const patchSceneLayoutRequest = createAsyncThunk<
  Scene,
  { layoutId: string; sceneId: string },
  ThunkApi
>(
  `${prefix} patchSceneLayoutRequest`,
  async ({ sceneId, layoutId }, thunkApi) => {
    const {
      drafts: { currentDraft },
      user: { featureFlags }
    } = thunkApi.getState();
    const result = await patchSceneLayout(sceneId, layoutId);
    const draftId = currentDraft.id;

    if (featureFlags[FeatureFlag.framePreviewFeature] && draftId) {
      thunkApi.dispatch(scenePreviewRequest({ draftId, sceneIds: [sceneId] }));
    }

    return result;
  },
  thunkOptions
);

const scenePreviewLocalUpdate = createAsyncThunk<
  void,
  { sceneId: string; orderId: string; progress?: number; loading: boolean },
  ThunkApi
>(
  `${prefix} scenePreviewLocalUpdate`,
  async ({ sceneId, orderId, progress, loading }, thunkApi) => {
    const {
      scenes: { framePreviewLoading }
    } = thunkApi.getState();
    const resource = framePreviewLoading[sceneId];

    if (resource && resource.orderId === orderId) {
      thunkApi.dispatch(
        scenesActions.setFramePreviewLoading({
          sceneId,
          orderId,
          progress,
          loading
        })
      );
    } else {
      console.warn("order id or resource is not found for preview");
    }
  },
  thunkOptions
);

const updateSceneWithAugmentedTextRequest = createAsyncThunk<
  Scene,
  { sceneId: string; augmentSelection: AugmentedSelection; source: string },
  ThunkApi
>(
  `${prefix} updateSceneWithAugmentedTextRequest`,
  async ({ sceneId, augmentSelection, source }, thunkApi) => {
    let type: AugmentedType;
    let modification: AugmentedModification | AugmentedSelection | undefined;
    switch (augmentSelection) {
      case AugmentedSelection.funny:
        type = AugmentedType.tone;
        modification = AugmentedSelection.funny;
        break;
      case AugmentedSelection.happy:
        type = AugmentedType.tone;
        modification = AugmentedSelection.happy;
        break;
      case AugmentedSelection.young:
        type = AugmentedType.tone;
        modification = AugmentedSelection.young;
        break;
      case AugmentedSelection.professional:
        type = AugmentedType.tone;
        modification = AugmentedSelection.professional;
        break;
      case AugmentedSelection.serious:
        type = AugmentedType.tone;
        modification = AugmentedSelection.serious;
        break;
      case AugmentedSelection.rephraseFree:
        type = AugmentedType.rephrase;
        modification = AugmentedModification.rephraseFree;
        break;
      case AugmentedSelection.rephrasePerson:
        type = AugmentedType.rephrase;
        modification = AugmentedModification.rephrasePerson;
        break;
      case AugmentedSelection.shorten:
        type = AugmentedType.length;
        modification = AugmentedModification.shorten;
        break;
      case AugmentedSelection.longer:
        type = AugmentedType.length;
        modification = AugmentedModification.expand;
        break;
      case AugmentedSelection.oneSentence:
        type = AugmentedType.length;
        modification = AugmentedModification.oneSentence;
        break;
      case AugmentedSelection.runAsPrompt:
        type = AugmentedType.free;
        break;
      default:
        throw new Error(`augmentSelection doesn't exist - ${augmentSelection}`);
    }

    const body = {
      scene_id: sceneId,
      augmentation_type: type,
      augmentation_modification: modification
    };
    const result = await updateSceneWithAugmentedText(body);

    thunkApi.dispatch(analyticsEvents.augmentScene({ source, selection: augmentSelection }));

    return result;
  },
  thunkOptions
);

const generateTranscriptFromTopicRequest = createAsyncThunk<
  GeneratedTranscriptResponse,
  {
    sceneId: string;
    augmentSelection: AugmentedSelection;
    source: string;
    tone?: PromptTone;
    name?: string;
    characteristics?: string;
    leadLinkedinProfile?: string;
    sdrLinkedinProfile?: string;
    productDescription?: string;
    prompt?: string;
  }
>(
  `${prefix} generateTranscriptFromTopicRequest`,
  async ({
    sceneId,
    augmentSelection,
    tone,
    name,
    characteristics,
    leadLinkedinProfile,
    sdrLinkedinProfile,
    productDescription,
    prompt
  }) => {
    let body: GenerateTranscript;
    switch (augmentSelection) {
      case AugmentedSelection.bio:
        body = {
          type: GenerateTranscriptType.bio
        };
        break;
      case AugmentedSelection.runAsPrompt:
        body = {
          type: GenerateTranscriptType.free,
          parameters: {
            prompt: prompt as string
          }
        };
        break;
      case AugmentedSelection.product:
        body = {
          type: GenerateTranscriptType.product,
          parameters: {
            name,
            characteristics,
            tone
          }
        };
        break;
      case AugmentedSelection.sales:
        body = {
          type: GenerateTranscriptType.sdr,
          parameters: {
            lead_linkedin_profile: leadLinkedinProfile,
            sdr_linkedin_profile: sdrLinkedinProfile,
            product_description: productDescription
          }
        };
        break;
      default:
        throw new Error(`augmentSelection doesn't exist - ${augmentSelection}`);
    }

    const result = await generateTranscript(body);
    const operations = [
      { op: "replace", path: "attributes.text.transcript.text", value: result.transcript }
    ] as PatchOperation[];
    await patchScene(sceneId, operations);

    return result;
  },
  thunkOptions
);

const patchVoiceSpeechRequest = createAsyncThunk<
  void,
  {
    sceneId: string;
    voiceAdjustments: { action: VoiceSpeechAudioAction; ratio?: number }[];
    assetKey: string;
  },
  ThunkApi
>(
  `${prefix} patchVoiceSpeechRequest`,
  async ({ sceneId, voiceAdjustments, assetKey }, thunkAPI) => {
    const state = thunkAPI.getState();
    const { currentDraft } = state.drafts;
    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.voice.${assetKey}.voice_adjustments`,
        value: voiceAdjustments
      }
    ];

    if (currentDraft.global_character) {
      await patchDraft(currentDraft.id as string, operations);
      if (state.drafts.currentDraft?.attributes?.voice?.voice) {
        thunkAPI.dispatch(
          draftsActions.updateCurrentDraftVoiceAdjustment({
            assetKey,
            speechData: voiceAdjustments
          })
        );
      }
    } else {
      await patchScene(sceneId, operations);
      if (state.scenes.entities[sceneId]?.attributes?.voice?.[assetKey]) {
        thunkAPI.dispatch(
          scenesActions.updateCurrentSceneVoiceAdjustment({ assetKey, sceneId, voiceAdjustments })
        );
      }
    }
  },
  thunkOptions
);

export default {
  patchSceneRequest,
  moveScene,
  getDraftsScenesRequest,
  getSceneByIdRequest,
  addDraftSceneRequest,
  deleteDraftSceneRequest,
  patchSceneLayoutRequest,
  scenePreviewRequest,
  scenePreviewLocalUpdate,
  updateSceneWithAugmentedTextRequest,
  generateTranscriptFromTopicRequest,
  patchVoiceSpeechRequest,
  duplicateSceneRequest
};
