import { useAppDispatch, useAppSelector } from "app/hooks";
import usePermissions from "app/hooks/usePermissions";
import { Draft, FetchStatus, InternalScene } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { scenesActions } from "app/store/slices/scenes.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneActionOrigin } from "app/store/thunks/analyticsEvents.thunk";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { useIntl } from "react-intl";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import SkipButton from "app/components/editor/scene/SkipButton";
import * as scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { DEFAULT_MAX_SCENES } from "app/config/planFeature";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const HoverButton = styled(Button)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    opacity: 0.6;
    background-color: transparent;
  }
`;

const VerticalLine = styled.div<{ $isSelected: boolean }>`
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  transition: 0.3s all ease-in-out;
  width: 1px;
  height: 20px;
  background-color: ${({ theme }) => theme.gray5};
  margin-top: 4px;
  margin-right: 4px;
`;

export const OutsideButtons = styled(H1_FlexRow)<{ $selected: boolean }>`
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: absolute;
  opacity: ${({ $selected }) => ($selected ? 1 : 0)};
  z-index: ${({ $selected }) => ($selected ? 8 : -1)};
  transition: 0.3s ease-in-out all;
  border-radius: ${({ $selected }) => ($selected ? "0 10px 10px 0" : "10px")};
  top: -16px;
  right: ${({ $selected }) => ($selected ? "274px" : "331px")};
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  && i {
    color: ${({ $selected, theme }) => ($selected ? theme.blue2 : theme.blue2)};
  }
  button {
    height: 28px;
  }
`;

interface SceneOutsideToolbarProps {
  idx: number;
  sceneId: string;
}
const SceneOutsideToolbar = ({ idx, sceneId }: SceneOutsideToolbarProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { isWriteWorkspaceAllow } = usePermissions();

  const numberOfScenes = useAppSelector(scenesSelectors.getTotalNumberOfScenes);
  const scene = useAppSelector((state) =>
    scenesGlobalSelectors.selectById(state, sceneId)
  ) as InternalScene;
  const isSkipDisabled = useAppSelector((state) => scenesSelectors.isSkipDisabled(state, sceneId));
  const selectedSceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const limits = useAppSelector(getLimits);

  const wandOverlaySceneId: string | undefined = useAppSelector(
    (state) => state.scenes.wandOverlaySceneId
  );
  const augmentedSceneStatus: FetchStatus = useAppSelector(
    (state) => state.scenes.augmentedSceneStatus
  );
  const generateTranscriptStatus: FetchStatus = useAppSelector(
    (state) => state.scenes.generateTranscriptStatus
  );
  const segmentToSceneStatus = useAppSelector((state) => state.drafts.segmentToSceneStatus);

  const isAugmentedSceneLoading = augmentedSceneStatus === fetchingStatus.loading;
  const isGenerateTranscriptStatusLoading = generateTranscriptStatus === fetchingStatus.loading;
  const isSegmentToSceneStatusLoading = segmentToSceneStatus === fetchingStatus.loading;

  const maxScenesLimit: number = limits?.scenes ?? DEFAULT_MAX_SCENES;
  const draftId: string = draft?.id as string;
  const sceneName = scene?.name;
  const transcriptUrl = scene?.attributes?.media?.transcript?.url;
  const isDeleteLoading = deleteSceneStatus === fetchingStatus.loading;
  const isSceneLoading = scenesStatus === fetchingStatus.loading;
  const isCreateSceneLoading = createSceneStatus === fetchingStatus.loading;
  const wandOverlayLoading =
    (isAugmentedSceneLoading ||
      isGenerateTranscriptStatusLoading ||
      isSegmentToSceneStatusLoading) &&
    wandOverlaySceneId === sceneId;
  const isSceneSelected = selectedSceneId === sceneId && !wandOverlayLoading && !transcriptUrl;
  const disableAllButtons =
    isSceneLoading ||
    isDeleteLoading ||
    isCreateSceneLoading ||
    !isWriteWorkspaceAllow ||
    wandOverlayLoading;

  const onDeletingScene = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      analyticsEvents.deleteScene({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: idx
        },
        action: SceneActionOrigin.Scene
      })
    );
    dispatch(
      scenesActions.deleteDraftSceneRequest({
        draftId,
        sceneId
      })
    );
  };

  const onDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      analyticsEvents.duplicateScene({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: idx
        },
        action: SceneActionOrigin.Scene
      })
    );
    dispatch(scenesActions.duplicateSceneRequest({ draftId, sceneId, parentId: scene.id }));
  };

  if (!isWriteWorkspaceAllow || wandOverlayLoading) {
    return null;
  }

  return (
    <OutsideButtons padding="0 10px 0 2px" $selected={isSceneSelected}>
      <VerticalLine $isSelected={isSceneSelected} />
      <NextUITooltip content={formatMessage(sceneMessages.duplicateSceneTooltip)}>
        <div>
          <HoverButton
            isDisabled={scenes.length >= maxScenesLimit || disableAllButtons}
            onClick={onDuplicate}
            variant="light"
            isIconOnly
            startContent={<i className="far fa-copy" />}
          />
        </div>
      </NextUITooltip>
      <SkipButton sceneId={sceneId} disabled={isSkipDisabled || disableAllButtons} />
      <NextUITooltip content={formatMessage(sceneMessages.deleteSceneTooltip)}>
        <div>
          <HoverButton
            isDisabled={disableAllButtons || numberOfScenes === 1}
            onClick={onDeletingScene}
            variant="light"
            isIconOnly
            startContent={<i className="far fa-trash" />}
          />
        </div>
      </NextUITooltip>
    </OutsideButtons>
  );
};

export default SceneOutsideToolbar;
