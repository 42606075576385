import { useAppDispatch, useAppSelector } from "app/hooks";
import UiActions from "app/store/actions/ui.actions";
import { AssetGroup } from "app/types";

export enum Drawer {
  Layout = "Layout",
  Comments = "Comments",
  Music = "Music",
  MusicV2 = "MusicV2",
  VideoWizard = "VideoWizard",
  Scene = "Scene",
  Voices = "Voices",
  Colors = "Colors",
  Presenter = "Presenter",
  ImageFit = "ImageFit",
  Images = "Images",
  Texts = "Texts",
  Video = "Video",
  Templates = "Templates",
  Intro = "Intro",
  Versions = "Versions",
  Export = "Export",
  EditingVideo = "EditingVideo",
  Brand = "Brand"
}

type DrawerName =
  | "layout"
  | "template"
  | "character"
  | "voice"
  | "media"
  | "Music"
  | "text"
  | "export"
  | "video"
  | "brand"
  | "renderingPanel"
  | AssetGroup;
const useDrawer = (id?: string) => {
  const dispatch = useAppDispatch();
  const editorDrawerOpen: DrawerName | Drawer = useAppSelector(
    (state: any) => state.userUi.editorDrawerOpen
  );
  const originContext: any | null = useAppSelector((state: any) => state.userUi.drawerOrigin) || {};

  const openDrawer = (drawerName: DrawerName | Drawer, extraContext?: Record<string, any>) => {
    let context = id ? { id } : {};
    dispatch(UiActions.setEditorDrawerOpen(drawerName));
    if (extraContext) {
      context = { ...context, ...extraContext };
    }
    dispatch(UiActions.setEditorDrawerOpenOrigin(context));
  };

  const closeDrawer = () => {
    dispatch(UiActions.setEditorDrawerOpen(null));
    dispatch(UiActions.setEditorDrawerOpenOrigin(null));
  };

  return { openDrawer, closeDrawer, editorDrawerOpen, originContext };
};

export default useDrawer;
