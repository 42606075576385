import { defineMessages } from "react-intl";

export const characterDrawerMessages = defineMessages({
  searchCharacterPlaceholder: {
    id: `editor.drawer.character.search-placeholder`,
    defaultMessage: "Search avatar..."
  },
  avatarNumber: {
    id: `editor.drawer.character.avatar-number`,
    defaultMessage: "Avatar {number}"
  },
  backToScene: {
    id: `editor.drawer.character.back-to-scene`,
    defaultMessage: "Back to scene"
  },
  backToAllCharacters: {
    id: `editor.drawer.character.back-to-all-characters`,
    defaultMessage: "Back to all avatars"
  },
  avatar: {
    id: `editor.drawer.character.avatar`,
    defaultMessage: "avatar"
  },
  changeGesture: {
    id: `editor.drawer.character.change-gesture`,
    defaultMessage: "Change gesture"
  },
  male: {
    id: `editor.drawer.character.male`,
    defaultMessage: "Male"
  },
  female: {
    id: `editor.drawer.character.female`,
    defaultMessage: "Female"
  },
  all: {
    id: `editor.drawer.character.all`,
    defaultMessage: "All"
  },
  mobile: {
    id: `editor.drawer.character.mobile`,
    defaultMessage: "Mobile"
  },
  studio: {
    id: `editor.drawer.character.studio`,
    defaultMessage: "Studio"
  },
  cinematic: {
    id: `editor.drawer.character.cinematic`,
    defaultMessage: "Cinematic"
  },
  webCam: {
    id: `editor.drawer.character.webcam`,
    defaultMessage: "Web Camera"
  },
  desk: {
    id: `editor.drawer.character.desk`,
    defaultMessage: "Desk"
  },
  upgradeCharacter: {
    id: "editor.drawer.character.upgrade-text",
    defaultMessage: "Upgrade to use this avatar"
  },
  filter: {
    id: "editor.drawer.character.filter",
    defaultMessage: "Filter"
  },
  resetAll: {
    id: "editor.drawer.character.reset-all",
    defaultMessage: "Reset All"
  },
  gender: {
    id: "editor.drawer.character.gender",
    defaultMessage: "Gender"
  },
  clothing: {
    id: "editor.drawer.character.clothing",
    defaultMessage: "Style"
  },
  hairStyle: {
    id: "editor.drawer.character.hair-style",
    defaultMessage: "Hair Style"
  },
  age: {
    id: "editor.drawer.character.age",
    defaultMessage: "Age"
  },
  price: {
    id: "editor.drawer.character.price",
    defaultMessage: "Price"
  },
  free: {
    id: "editor.drawer.character.free",
    defaultMessage: "Free"
  },
  pro: {
    id: "editor.drawer.character.pro",
    defaultMessage: "Pro"
  },
  displayAvatars: {
    id: "editor.drawer.character.display-avatars",
    defaultMessage: "Display avatars"
  },
  myAvatars: {
    id: "editor.drawer.character.my-avatars",
    defaultMessage: "My avatars"
  },
  publicAvatars: {
    id: "editor.drawer.character.public-avatars",
    defaultMessage: "Public avatars"
  },
  applyFilters: {
    id: "editor.drawer.character.apply-filters",
    defaultMessage: "Apply Filters"
  },
  unsupportedCharacter: {
    id: "editor.drawer.character.unsupported-character",
    defaultMessage:
      "The current template is not compatible with this avatar. Please switch to a different template to use it."
  },
  emptyText: {
    id: "editor.drawer.character.empty-text",
    defaultMessage: "Oops, looks like we couldn't find any avatars"
  },
  emptyDescription: {
    id: "editor.drawer.character.empty-description",
    defaultMessage: "Try changing your filters and try again?"
  },
  myAvatar: {
    id: "editor.drawer.character.my-avatar",
    defaultMessage: "My Avatar"
  },
  gesture: {
    id: "editor.drawer.character.gesture",
    defaultMessage: "Gesture"
  },
  yourCharacter: {
    id: `editor.drawer.character.your-character`,
    defaultMessage: `Your Character`
  },
  change: {
    id: `editor.drawer.character.change`,
    defaultMessage: `Change`
  },
  applyAllScenes: {
    id: `editor.drawer.character.apply-all-scenes`,
    defaultMessage: `Apply all scenes`
  },
  applyOneScene: {
    id: `editor.drawer.character.apply-one-scene`,
    defaultMessage: `Apply {element} per scene`
  },
  emojiNatural: {
    id: `editor.drawer.character.emoji-natural`,
    defaultMessage: `Natural`
  },
  emojiFriendly: {
    id: `editor.drawer.character.emoji-friendly`,
    defaultMessage: `Friendly`
  },
  emojiNeutral: {
    id: `editor.drawer.character.emoji-neutral`,
    defaultMessage: `Neutral`
  },
  emojiMotionLess: {
    id: `editor.drawer.character.emoji-motionless`,
    defaultMessage: `Motionless`
  },
  emojiActive: {
    id: `editor.drawer.character.emoji-active`,
    defaultMessage: `Active`
  },
  emojiEnergetic: {
    id: `editor.drawer.character.emoji-energetic`,
    defaultMessage: `Energetic`
  },
  emojiSubtle: {
    id: `editor.drawer.character.emoji-subtle`,
    defaultMessage: `Subtle`
  },
  emojiExpressive: {
    id: `editor.drawer.character.emoji-expressive`,
    defaultMessage: `Expressive`
  }
});
