/* eslint-disable camelcase */

import { createAsyncThunk } from "@reduxjs/toolkit";
import TagManager from "react-gtm-module";
import Config from "app/config/Config";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import * as segment from "app/utils/segment";
import { ListenStatus } from "app/store/slices/voices.slice";
import { Drawer } from "app/hooks/useDrawer";
import {
  EditorView,
  FitOptions,
  MFATypes,
  Orientation,
  PaymentModalAction,
  PaymentModalPhase,
  PaymentModalSource,
  PaymentPeriod,
  PlanEnum,
  PlanToUpgrade,
  ProtectionType,
  ReplacedBannerType,
  SceneTabs,
  SelectedScene,
  SocialMedia,
  UseCaseSegmentType,
  VideoFeedbackReaction
} from "app/types";
import { FilterType, MediaLibraryTabs, MediaType, MediaTypeSort } from "app/types/media";
import { BackgroundMusic } from "app/types/music";
import { AugmentedSelection } from "app/types/services";
import { Align } from "filestack-js";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import redux from "app/store/store";
import { isMobile } from "react-device-detect";
import { ReactNode } from "react";
import { VirtualTwinSteps } from "app/types/virtualTwin";

const prefix = "[AnalyticsEvents]";

interface VideoContext {
  draftId?: string;
  req_id?: string;
}

interface shareVideoCopyContext extends VideoContext {
  source: string;
  publish: boolean;
}

export interface UserIdentify {
  id: string;
  permissions: string[];
  email: string;
  isMobile?: boolean;
  data?: unknown;
}

export interface EnrichUserIdentify {
  id: string;
  data?: object;
}
interface TrackEventProps {
  eventName: string;
  properties: null | Record<string, unknown>;
}

export interface JoinExperimentProps {
  id: string;
  email: string;
  experimentName: string;
  data?: unknown;
}

export enum CloneVoiceType {
  file = "file",
  record = "record"
}
export const initAnalytics = () => {
  TagManager.initialize({
    gtmId: Config.gtmId,
    auth: Config.gtmAuth,
    preview: Config.gtmPreview
  });
  segment.load();
};

const gaDataLayerSend = (properties: null | Record<string, any>, eventName: string): void =>
  TagManager.dataLayer({
    dataLayer: {
      ...properties,
      appVersion: Config.release,
      event: eventName,
      workspaceId: getCurrentWorkspace(redux.store.getState())?.id,
      teamId: getCurrentWorkspace(redux.store.getState())?.current_team?.id,
      isMobile
    }
  });

export const resetAnalytics = createAsyncThunk(
  `${prefix} resetAnalytics`,
  async () => {
    segment.reset();
  },
  thunkOptions
);

export const userIdentify = createAsyncThunk(
  `${prefix} userIdentify`,
  async (payload: UserIdentify) => {
    gaDataLayerSend(
      {
        userId: payload.id,
        permissions: payload.permissions.join(", "),
        email: payload.email,
        isMobile: payload.isMobile,
        data: payload.data || {}
      },
      "set_user_data"
    );
    segment.identify(
      payload.id,
      payload.permissions.join(", "),
      payload.email,
      getCurrentWorkspace(redux.store.getState())?.id || "",
      getCurrentWorkspace(redux.store.getState())?.current_team?.id || "",
      payload.data || {},
      // @ts-ignore - Need more than 6 parameters here
      payload.isMobile
    );
  },
  thunkOptions
);

export const enrichUserIdentify = createAsyncThunk(
  `${prefix} enrichUserIdentify`,
  async (payload: EnrichUserIdentify) => {
    segment.enrichIdentify(payload.id, payload.data || {});
  },
  thunkOptions
);

export const trackEvent = createAsyncThunk(
  `${prefix} trackEvent`,
  async (props: TrackEventProps) => {
    const properties = props.properties ?? {};
    gaDataLayerSend(properties, props.eventName);
    segment.track(props.eventName, properties);
  },
  thunkOptions
);

export const somethingWentWrongEvent = createAsyncThunk(
  `${prefix} somethingWentWrongEvent`,
  async (context: {
    stack?: { request: string[]; response: Response };
    status?: number;
    message?: string;
    extra?: Record<string, string>;
  }) => {
    const event = "app:draft_somethingWentWrong";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const augmentScene = createAsyncThunk(
  `${prefix} augmentScene`,
  async (context: { source: string; selection: AugmentedSelection }) => {
    const event = "app:scene_augmentScene";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "augment_scene",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const onClickMfa = createAsyncThunk(
  `${prefix} onClickMfa`,
  async (context: { type: MFATypes; action: "enable" | "disable" }) => {
    const event = "app:profile_onClickMfa";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const imageFitTypeChange = createAsyncThunk(
  `${prefix} imageFitTypeChange`,
  async (context: { type: FitOptions }) => {
    const event = "app:scene_changeMediaFitType";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const imageFitChange = createAsyncThunk(
  `${prefix} imageFitChange`,
  async (context: { fitType: FitOptions; filterType: FilterType; sceneId: string }) => {
    const event = "app:scene_changeMediaFit";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const templateCategorySelect = createAsyncThunk(
  `${prefix} templateCategorySelect`,
  async (context: { categoryName: string }) => {
    const event = "app:templates_categorySelect";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeRecipe = createAsyncThunk(
  `${prefix} changeRecipe`,
  async (context: { recipeName: string; recipeId: string }) => {
    const event = "app:draft_changeRecipe";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changePresenterPageFilters = createAsyncThunk(
  `${prefix} changePresenterPageFilters`,
  async (context: { value: any; filter: string }) => {
    const event = "app:presenterPage_changeFilters";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const presenterSearch = createAsyncThunk(
  `${prefix} presenterSearch`,
  async (context: { value: string; source: string }) => {
    const event = "app:presenterPage_presenterSearch";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectPresenterPageCharacter = createAsyncThunk(
  `${prefix} selectPresenterPageCharacter`,
  async (context: { selectedCharacter: string }) => {
    const event = "app:presenterPage_selectCharacter";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const templateSearch = createAsyncThunk(
  `${prefix} templateSearch`,
  async (context: { value: string }) => {
    const event = "app:app_templateSearch";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const changeOrientation = createAsyncThunk(
  `${prefix} changeOrientation`,
  async (context: { source: string; orientation: Orientation }) => {
    const event = "app:app_changeOrientation";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const workflowCreateVideo = createAsyncThunk(
  `${prefix} workflowCreateVideo`,
  async (context: {
    workflowId?: string;
    source: string;
    dataStoreId?: string;
    isPublishMode: boolean;
    dataStoreContentId?: string;
  }) => {
    const event = "app:workflow_createVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const viewSubmission = createAsyncThunk(
  `${prefix} viewSubmission`,
  async (context: { workflowId?: string; submissionId: string; source: string }) => {
    const event = "app:workflow_viewSubmission";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const runSubmission = createAsyncThunk(
  `${prefix} runSubmission`,
  async (context: { workflowId?: string; submissionId: string; source: string; type: string }) => {
    const event = "app:workflow_runSubmission";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mergePlaylistVideo = createAsyncThunk(
  `${prefix} mergePlaylistVideo`,
  async (context: { playlistId?: string; title: string }) => {
    const event = "app:playlist_mergePlaylistVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const deleteBulkDrafts = createAsyncThunk(
  `${prefix} deleteBulkDrafts`,
  async (context: { draftsIds: string[] }) => {
    const event = "app:draft_deleteBulkDrafts";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const createNewPlaylist = createAsyncThunk(
  `${prefix} createNewPlaylist`,
  async (context: { source: string; title: string; folderId?: string }) => {
    const event = "app:playlist_createNewPlaylist";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const workflowShareWorkflow = createAsyncThunk(
  `${prefix} workflowShareWorkflow`,
  async (context: { workflowId?: string; action: string; source: string }) => {
    const event = "app:workflow_shareWorkflow";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const workflowCsvRunAll = createAsyncThunk(
  `${prefix} workflowCsvRunAll`,
  async (context: { workflowId?: string; dataStoreContentIds: string[]; dataStoreId: string }) => {
    const event = "app:workflow_csvRunAll";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const workflowCsvExport = createAsyncThunk(
  `${prefix} workflowCsvExport`,
  async (context: { workflowId?: string; dataStoreContentIds: string[]; dataStoreId: string }) => {
    const event = "app:workflow_csvExport";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const workflowCsvRunSelected = createAsyncThunk(
  `${prefix} workflowCsvRunSelected`,
  async (context: { workflowId?: string; dataStoreContentIds: string[]; dataStoreId: string }) => {
    const event = "app:workflow_csvRunSelected";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const onClickDraftPublishMode = createAsyncThunk(
  `${prefix} onClickDraftPublishMode`,
  async (context: { workflowId?: string; isPublishMode: boolean; isDraftMode: boolean }) => {
    const event = "app:workflow_onClickDraftPublishMode";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const saveAsRecipe = createAsyncThunk(
  `${prefix} saveAsRecipe`,
  async (context: { draftId: string; title: string; description?: string }) => {
    const event = "app:draft_saveAsRecipe";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const salesMeetStartGoogleMeetSession = createAsyncThunk(
  `${prefix} salesMeetStartGoogleMeetSession`,
  async () => {
    const context = {};
    const event = "app:user_salesMeetStartGoogleMeetSession";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const salesMeetSelectTemplate = createAsyncThunk(
  `${prefix} salesMeetSelectTemplate`,
  async (context: { recipeId: string; title: string }) => {
    const event = "app:user_salesMeetSelectTemplate";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const salesMeetRemoveBot = createAsyncThunk(
  `${prefix} salesMeetRemoveBot`,
  async () => {
    const context = {};
    const event = "app:user_salesMeetRemoveBot";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const salesMeetStartRecordedSession = createAsyncThunk(
  `${prefix} salesMeetStartRecordedSession`,
  async () => {
    const context = {};
    const event = "app:user_salesMeetStartRecordedSession";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const deleteRecipe = createAsyncThunk(
  `${prefix} deleteRecipe`,
  async (context: { recipeId: string; categoryName: string }) => {
    const event = "app:template_deleteRecipe";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const activateVideoDrawerPrompt = createAsyncThunk(
  `${prefix} activateVideoDrawerPrompt`,
  async (context: { promptValue: string; numberOfScenes: number }) => {
    const event = "app:editor_activateVideoDrawerPrompt";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const revertVideoWizard = createAsyncThunk(
  `${prefix} revertVideoWizard`,
  async () => {
    const context = {};
    const event = "app:editor_revertVideoWizard";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mobileFinishPage = createAsyncThunk(
  `${prefix} mobileFinishPage`,
  async ({ action }: { action: string }) => {
    const context = { action };
    const event = "app:mobile_finishPage";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mobilePromptPage = createAsyncThunk(
  `${prefix} mobilePromptPage`,
  async ({ action }: { action: string }) => {
    const context = { action };
    const event = "app:mobile_promptPage";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const templateSelectedModal = createAsyncThunk(
  `${prefix} templateSelectedModal`,
  async (context: {
    source:
      | "editor-page"
      | "sider"
      | "empty-videos-state"
      | "folder-content-page"
      | "template-select-modal"
      | "templates-collection"
      | "templates-collection-mobile"
      | "templates-page"
      | "presentation-modal";
    templateName: string;
    category?: string;
  }) => {
    const event = "app:templates_templateSelect";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const navigateToEditor = createAsyncThunk(
  `${prefix} navigateToEditor`,
  async (context: { source: string }) => {
    const event = "app:draft_navigateToEditor";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editedVideoName = createAsyncThunk(
  `${prefix} editedVideoName`,
  async () => {
    const event = "app:draft_nameEdit";
    gaDataLayerSend(null, event);
    segment.track(event, null);
  },
  thunkOptions
);

export const changeWorkspace = createAsyncThunk(
  `${prefix} changeWorkspace`,
  async (context: {
    workspaceId: string;
    workspaceName: string;
    teamId: string;
    teamName: string;
  }) => {
    const event = "app:workspace_changeWorkspace";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const requestTemplate = createAsyncThunk(
  `${prefix} requestTemplate`,
  async () => {
    const event = "app:app_requestTemplate";
    const context = {};
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const customTemplate = createAsyncThunk(
  `${prefix} customTemplate`,
  async () => {
    const event = "app:app_customTemplate";
    const context = {};
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editorPauseRemoved = createAsyncThunk(
  `${prefix} editorPauseRemoved`,
  async (context: { time: string; nodeKey: string }) => {
    const event = "app:editor_pauseRemoved";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editorPauseClosed = createAsyncThunk(
  `${prefix} editorPauseClosed`,
  async (context: { time: string; nodeKey: string }) => {
    const event = "app:editor_pauseClosed";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const editorPauseCreated = createAsyncThunk(
  `${prefix} editorPauseCreated`,
  async () => {
    const event = "app:editor_pauseCreated";
    const context = {};
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const takeVideoPreviewTour = createAsyncThunk(
  `${prefix} takeVideoPreviewTour`,
  async () => {
    const event = "app:editor_takeVideoPreviewTour";
    const context = {};
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editorPronunciationRemoved = createAsyncThunk(
  `${prefix} editorPronunciationRemoved`,
  async (context: { text: string; pronounced: string; nodeKey: string }) => {
    const event = "app:editor_pronunciationRemoved";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "add_pronunciation",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const previewPhrase = createAsyncThunk(
  `${prefix} previewPhrase`,
  async (context: { text: string; pronounced: string; nodeKey: string }) => {
    const event = "app:editor_previewPhrase";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editorPronunciationClosed = createAsyncThunk(
  `${prefix} editorPronunciationClosed`,
  async (context: { text: string; pronounced: string; nodeKey: string }) => {
    const event = "app:editor_pronunciationClosed";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "add_pronunciation",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);
export const editorPronunciationCreated = createAsyncThunk(
  `${prefix} editorPronunciationCreated`,
  async (context: { text: string }) => {
    const event = "app:editor_pronunciationCreated";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "add_pronunciation",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const createWorkspace = createAsyncThunk(
  `${prefix} createWorkspace`,
  async (context: { workspaceName: string }) => {
    const event = "app:workspace_createWorkspace";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const leaveSite = createAsyncThunk(
  `${prefix} leaveSite`,
  async () => {
    const event = "app:leaveSite";
    const context = {};
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const inviteMembers = createAsyncThunk(
  `${prefix} inviteMembers`,
  async (context: { workspaceId: string; workspaceName: string; members: string[] }) => {
    const event = "app:workspace_inviteMember";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const removeMember = createAsyncThunk(
  `${prefix} removeMember`,
  async (context: { workspaceId: string; workspaceName: string; member: string }) => {
    const event = "app:workspace_removeMember";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editTranscript = createAsyncThunk(
  `${prefix} editTranscript`,
  async (context: { length: number; sceneId: string; sceneIndex: number; language?: string }) => {
    const event = "app:scene_transcriptEdit";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_transcript",
      eventId: event,
      properties: { sceneId: context.sceneId }
    });
  },
  thunkOptions
);

export const chooseCharacter = createAsyncThunk(
  `${prefix} chooseCharacter`,
  async (context: { id: string; name: string; gender: string; pro: boolean }) => {
    const event = "app:draft_chooseCharacter";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "select_character",
      eventId: event,
      properties: { ...context, pro: String(context.pro).toString() }
    });
  },
  thunkOptions
);

export const formSubmit = createAsyncThunk(
  `${prefix} formSubmit`,
  async (context: {
    formId: string;
    formName: string;
    status: "submitted" | "submit_failed";
    data?: any;
  }) => {
    const event = "app:user_formSubmit";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const chooseVoice = createAsyncThunk(
  `${prefix} chooseVoice`,
  async (context: { id: string; name: string; gender: string; pro: boolean }) => {
    const event = "app:draft_chooseVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "select_voice",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const changeCharacterEmotion = createAsyncThunk(
  `${prefix} changeCharacterEmotion`,
  async (context: { value: ReactNode; characterId: string }) => {
    const event = "app:editor_changeCharacterEmotion";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeFilterType = createAsyncThunk(
  `${prefix} changeFilterType`,
  async (context: { value: string }) => {
    const event = "app:editor_changeFilterType";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const avatarPanelSearch = createAsyncThunk(
  `${prefix} avatarPanelSearch`,
  async (context: { value: string }) => {
    const event = "app:editor_avatarPanelSearch";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeVoicesFilters = createAsyncThunk(
  `${prefix} changeVoicesFilters`,
  async (context: { value: string | boolean | undefined; filter: string }) => {
    const event = "app:editor_changeVoicesFilters";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeCharacterFavorite = createAsyncThunk(
  `${prefix} changeCharacterFavorite`,
  async (context: { value: boolean; characterId: string; source: string }) => {
    const event = "app:editor_changeCharacterFavorite";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const characterGestures = createAsyncThunk(
  `${prefix} characterGestures`,
  async (context: { value?: string; characterId?: string; source?: string }) => {
    const event = "app:editor_characterGestures";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeVoiceFavorite = createAsyncThunk(
  `${prefix} changeVoiceFavorite`,
  async (context: { value: boolean; voiceId: string; source: string }) => {
    const event = "app:editor_changeVoiceFavorite";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeMultipleCharacterSettings = createAsyncThunk(
  `${prefix} changeMultipleCharacterSettings`,
  async (context: { clickAllScenes: boolean; draftId: string }) => {
    const event = "app:editor_changeMultipleCharacterSettings";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const searchVoice = createAsyncThunk(
  `${prefix} searchVoice`,
  async (context: { query: string }) => {
    const event = "app:editor_searchVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const cloneVoice = createAsyncThunk(
  `${prefix} cloneVoice`,
  async () => {
    const event = "app:editor_cloneVoice";
    gaDataLayerSend({}, event);
    segment.track(event, {});
  },
  thunkOptions
);

export const recordVoice = createAsyncThunk(
  `${prefix} recordVoice`,
  async (context: { action: "start" | "stop" }) => {
    const event = "app:editor_recordVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const chooseVoiceFile = createAsyncThunk(
  `${prefix} chooseVoiceFile`,
  async (context: { file: string }) => {
    const event = "app:editor_recordVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const createCloneVoice = createAsyncThunk(
  `${prefix} createCloneVoice`,
  async (context: { type: CloneVoiceType; time: number }) => {
    const event = "app:editor_createCloneVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const publishVideos = createAsyncThunk<void, VideoContext>(
  `${prefix} publishVideos`,
  async (context: VideoContext) => {
    const event = "app:video_publishVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const shareVideoCopy = createAsyncThunk<void, shareVideoCopyContext>(
  `${prefix} shareVideoCopy`,
  async (context: shareVideoCopyContext) => {
    const event = "app:video_shareLinkCopy";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "copy_link",
      eventId: event,
      properties: { ...context, publish: String(context.publish).toString() }
    });
  },
  thunkOptions
);

export const updateVideoAvailability = createAsyncThunk<
  void,
  { videoId: string; checked: boolean }
>(
  `${prefix} updateVideoAvailability`,
  async (context: { videoId: string; checked: boolean }) => {
    const event = "app:video_updateVideoAvailability";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const shareVideoViaSocial = createAsyncThunk<
  void,
  {
    source: string;
    socialMedia: SocialMedia;
    videoId: string;
    req_id: string;
    isFirstPublished: boolean;
  }
>(
  `${prefix} shareVideoViaSocial`,
  async (context: {
    source: string;
    socialMedia: SocialMedia;
    videoId: string;
    req_id: string;
    isFirstPublished: boolean;
  }) => {
    const event = "app:video_shareVideoViaSocial";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const reactAVideo = createAsyncThunk(
  `${prefix} reactAVideo`,
  async (context: { activate: boolean; reaction: string; videoId: string }) => {
    const event = "app:video_userReactAVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const feedbackAVideo = createAsyncThunk(
  `${prefix} feedbackAVideo`,
  async (context: { reaction: VideoFeedbackReaction; videoId: string }) => {
    const event = "app:video_userFeedbackVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const leaveTeam = createAsyncThunk(
  `${prefix} leaveTeam`,
  async (context: { workspaceId: string; workspaceName: string; userEmail: string }) => {
    const event = "app:workspace_leaveTeam";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

// For now it solved by @viamax using filtered mixpanel option that counts the user's first play as userActiovation
export const userActivation = createAsyncThunk<void>(
  `${prefix} userActivation`,
  async () => {
    const context = {};
    const event = "app:user_activation";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const acquisition = createAsyncThunk<void>(
  `${prefix} acquisition`,
  async () => {
    const context = {};
    const event = "app:acquisition";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const showOlderVersions = createAsyncThunk<void, { source: string }>(
  `${prefix} showOlderVersions`,
  async ({ source }) => {
    const context = { source };
    const event = "app:editor_showOlderVersions";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const uploadMedia = createAsyncThunk(
  `${prefix} uploadMedia`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:editor_mediaUpload";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const watchLatest = createAsyncThunk<void, { source: string }>(
  `${prefix} watchLatest`,
  async ({ source }) => {
    const context = { source };
    const event = "app:editor_watchLatest";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const embedIntent = createAsyncThunk<void, { source: string }>(
  `${prefix} embedIntent`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_embedIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "embed_video",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const embedVideo = createAsyncThunk<void, { embeddedUrl: string; videoId: string }>(
  `${prefix} embedVideo`,
  async ({ embeddedUrl, videoId }) => {
    const context = { embeddedUrl, videoId };
    const event = "app:video_embed";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
    tracker({
      featureId: "embed_video",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const playgroundCopyLinkIntent = createAsyncThunk<void, { videoId?: string }>(
  `${prefix} playgroundCopyLinkIntent`,
  async ({ videoId }) => {
    const context = { videoId };
    const event = "app:playground_userCopyLinkIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "copy_link",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const playgroundGenerateVideo = createAsyncThunk<
  void,
  {
    translateLanguage?: string;
    transcript?: string;
    selectedVoiceId?: string;
    isCustomVoiceSet?: boolean;
    isCustomPresenterSet?: boolean | string;
    customPresenter: any;
  }
>(
  `${prefix} playgroundGenerateVideo`,
  async (context) => {
    const event = "app:playground_userGeneratedVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundPreviewTranscript = createAsyncThunk<
  void,
  { transcript?: string; selectedVoiceId?: string; isCustomVoiceSet: boolean }
>(
  `${prefix} playgroundPreviewTranscript`,
  async (context) => {
    const event = "app:playground_userPreviewTranscript";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundTranscriptChange = createAsyncThunk<void, { value: string }>(
  `${prefix} playgroundTranscriptChange`,
  async ({ value }) => {
    const context = { value };
    const event = "app:playground_userTranscriptChange";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const playgroundPresenterSearch = createAsyncThunk<void, { value: string }>(
  `${prefix} playgroundPresenterSearch`,
  async ({ value }) => {
    const context = { value };
    const event = "app:playground_userPresenterSearch";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundPresenterSelection = createAsyncThunk<void, { selectedPresenterId: string }>(
  `${prefix} playgroundPresenterSelection`,
  async ({ selectedPresenterId }) => {
    const context = { selectedPresenterId };
    const event = "app:playground_userPresenterSelection";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundVoicesUseAudio = createAsyncThunk<void, void>(
  `${prefix} playgroundVoicesUseAudio`,
  async () => {
    const context = {};
    const event = "app:playground_userUseAudioVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundPlayVoicePreview = createAsyncThunk<void, { voiceId: string }>(
  `${prefix} playgroundPlayVoicePreview`,
  async ({ voiceId }) => {
    const context = { voiceId };
    const event = "app:playground_userPlayVoicePreview";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundVoiceSearch = createAsyncThunk<void, { value: string }>(
  `${prefix} playgroundVoiceSearch`,
  async ({ value }) => {
    const context = { value };
    const event = "app:playground_userVoiceSearch";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundVideoScroll = createAsyncThunk<void, { videoId?: string }>(
  `${prefix} playgroundVideoScroll`,
  async ({ videoId }) => {
    const context = { videoId };
    const event = "app:playground_userVideoScroll";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const playgroundDownloadIntent = createAsyncThunk<void, { videoId?: string }>(
  `${prefix} playgroundDownloadIntent`,
  async ({ videoId }) => {
    const context = { videoId };
    const event = "app:playground_userDownloadVideoIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "download_video",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const shareSocialMediaIntent = createAsyncThunk<void, { source: string }>(
  `${prefix} shareSocialMediaIntent`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_shareSocialMediaIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const copyLinkIntent = createAsyncThunk<void, { source: string }>(
  `${prefix} copyLinkIntent`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_copyLinkIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectPresenterLanguage = createAsyncThunk<
  void,
  { characterId: string; characterTitle: string; language?: string }
>(
  `${prefix} selectPresenterLanguage`,
  async ({ characterId, characterTitle, language }) => {
    const context = { characterId, characterTitle, language };
    const event = "app:presenter_selectLanguage";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const createPresenterDraft = createAsyncThunk<
  void,
  { characterId: string; characterTitle: string }
>(
  `${prefix} createPresenterDraft`,
  async ({ characterId, characterTitle }) => {
    const context = { characterId, characterTitle };
    const event = "app:presenter_createDraft";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const downloadIntent = createAsyncThunk<void, { source: string }>(
  `${prefix} downloadIntent`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_downloadIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const downloadVideo = createAsyncThunk<void, VideoContext>(
  `${prefix} downloadVideo`,
  async ({ draftId, req_id }) => {
    const context = { draftId, req_id };
    const event = "app:video_downloadVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "download_video",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const downloadIntentScorm = createAsyncThunk<void, { source: string }>(
  `${prefix} downloadIntent`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_downloadIntentScorm";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const downloadScorm = createAsyncThunk<void, VideoContext>(
  `${prefix} downloadScorm`,
  async ({ draftId, req_id }) => {
    const context = { draftId, req_id };
    const event = "app:video_downloadScorm";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeSceneTab = createAsyncThunk(
  `${prefix} changeSceneTab`,
  async (context: { source: string; tab: SceneTabs }) => {
    const event = "app:scene_changeTab";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const createModalClose = createAsyncThunk(
  `${prefix} createModalClose`,
  async (context: { draftId: string }) => {
    const event = "app:createModal_close";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const cancelVideoRendering = createAsyncThunk(
  `${prefix} cancelVideoRendering`,
  async (context: { draftId: string; req_id: string; videoId: string }) => {
    const event = "app:createModal_cancel";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const playModalClose = createAsyncThunk(
  `${prefix} playModalClose`,
  async (context: { draftId: string; videoId: string }) => {
    const event = "app:playModal_close";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mobileModalClose = createAsyncThunk(
  `${prefix} mobileModalClose`,
  async () => {
    const context = {};
    const event = "app:mobileModal_close";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mobileModalOnClicked = createAsyncThunk(
  `${prefix} mobileModalAppStoreClicked`,
  async (context: { item: string }) => {
    const event = "app:mobileModal_appStoreClicked";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const createVideo = createAsyncThunk(
  `${prefix} createVideo`,
  async (context: {
    draftId: string;
    req_id?: string;
    success: boolean;
    characterId?: string;
    voiceId?: string;
    view?: EditorView;
    source?: string;
  }) => {
    const event = "app:video_createVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "create_video",
      eventId: event,
      properties: { ...context, success: String(context.success).toString() }
    });
  },
  thunkOptions
);

export const onPaymentSuccess = createAsyncThunk<
  void,
  { plan: PlanEnum; paymentPeriod: PaymentPeriod }
>(
  `${prefix} onPaymentSuccess`,
  async ({ plan, paymentPeriod }) => {
    const context = { plan, paymentPeriod };
    const event = "app:onPaymentSuccess";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const exportSubtitles = createAsyncThunk<void, { source: string }>(
  `${prefix} exportSubtitles`,
  async ({ source }) => {
    const context = { source };
    const event = "app:video_exportSubtitlesVTT";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const openPaymentModal = createAsyncThunk<void, { source: string }>(
  `${prefix} openPaymentModal`,
  async ({ source }) => {
    const context = { source };
    const event = "app:user_openPaymentModal";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const productUpgradeCta = createAsyncThunk<void, { cta: string }>(
  `${prefix} productUpgradeCta`,
  async ({ cta }) => {
    const context = { cta };
    const event = "app:user_upgradeIntent";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const maximumSeatsSupportClicked = createAsyncThunk<void, void>(
  `${prefix} maximumSeatsSupportClicked`,
  async () => {
    const context = {};
    const event = "app:user_maximumSeatsSupportClicked";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const inviteMemberModalOpen = createAsyncThunk<void, { source: string }>(
  `${prefix} inviteMemberModalOpen`,
  async ({ source }) => {
    const context = { source };
    const event = "app:user_inviteMemberModalOpen";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const submitWorkspaceInvite = createAsyncThunk<
  void,
  { inviteId: string; isApproved: boolean; workspaceId?: string }
>(
  `${prefix} submitWorkspaceInvite`,
  async ({ inviteId, isApproved, workspaceId }) => {
    const context = { inviteId, isApproved, workspaceId };
    const event = "app:workspace_submitInvite";

    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const togglePaymentPeriod = createAsyncThunk<void, { paymentPeriod: PaymentPeriod }>(
  `${prefix} togglePaymentPeriod`,
  async ({ paymentPeriod }) => {
    const context = { paymentPeriod };
    const event = "app:user_togglePaymentPeriod";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const comparePlans = createAsyncThunk(
  `${prefix} comparePlans`,
  async () => {
    const context = {};
    const event = "app:user_comparePlans";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const pricingUpgrade = createAsyncThunk<void, { plan: string; numberOfMinutes?: number }>(
  `${prefix} productUpgradeCta`,
  async ({ plan }) => {
    const context = { plan };
    const event = "app:user_pricingUpgrade";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const pricingMeetingRequest = createAsyncThunk<void, { source: string }>(
  `${prefix} pricingMeetingRequest`,
  async ({ source }) => {
    const context = { source };
    const event = "app:user_pricingMeetingRequest";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const audioPreview = createAsyncThunk<
  void,
  { context: "scene" | "video"; action: ListenStatus }
>(
  `${prefix} audioPreview`,
  async (context) => {
    const event = "app:editor_audioPreview";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: context.context === "scene" ? "listen" : "listen_all",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const mediaUploaderPresentation = createAsyncThunk<
  void,
  { status: "success" | "fail"; slidesNumber: number }
>(
  `${prefix} mediaUploaderPresentation`,
  async (context) => {
    const event = "app:media_uploadPresentation";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const templatesBrowse = createAsyncThunk<
  void,
  { source: "recommended" | "eLearningModal" | "templateCollection" }
>(
  `${prefix} templatesBrowse`,
  async (context) => {
    const event = "app:templates_browse";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const rightMenuClick = createAsyncThunk(
  `${prefix} rightMenuClick`,
  async (context: { selection: Drawer; selectedScene: SelectedScene }) => {
    const event = "app:editor_rightMenuClick";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export enum NewVideoType {
  integrations = "integrations",
  podcast = "podcast",
  video = "video",
  presentation = "presentation",
  videoWizard = "videoWizard",
  selfie = "selfie",
  playground = "playground",
  zoom = "zoom",
  createCharacter = "createCharacter",
  iconic = "iconic",
  importDocument = "importDocument",
  importUrl = "importUrl",
  script = "script"
}

export const newVideo = createAsyncThunk(
  `${prefix} newVideo`,
  async (context: { source: string; type: NewVideoType }) => {
    const event = "app:app_newVideo";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const videoWizardV2 = createAsyncThunk(
  `${prefix} videoWizardV2`,
  async (context: {
    source: string;
    action: string;
    stepIndex?: number;
    videoUseCase?: string;
    data?: Record<string, string>;
    format?: string;
    style?: string;
    duration?: string;
    draftId?: string;
  }) => {
    const event = "app:app_videoWizard";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "video_wizard",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const homepageCTA = createAsyncThunk(
  `${prefix} homepageCTA`,
  async (context: { source: string; actionDescription: string }) => {
    const event = "app:app_homepageCTA";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const navigate = createAsyncThunk(
  `${prefix} navigate`,
  async (context: { source: string; value: string }) => {
    const event = "app:app_navigate";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const videoPlay = createAsyncThunk(
  `${prefix} video_play`,
  async (context: { source: string; videoId?: string }) => {
    const event = "app:open_modal_video_play";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editDraft = createAsyncThunk(
  `${prefix} draft_edit`,
  async (context: { source: string; draftId: string }) => {
    const event = "app:draft_edit";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const headerClick = createAsyncThunk(
  `${prefix} headerClick`,
  async (context: { value: string; category: "Use Cases" | "Resources" }) => {
    const event = "app:app_headerClick";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const mobileCharacterModalClick = createAsyncThunk(
  `${prefix} mobileCharacterModalClick`,
  async (context: { action: string }) => {
    const event = "app:user_characterModal";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selfieModalClick = createAsyncThunk(
  `${prefix} selfieModalClick`,
  async () => {
    const context = {};
    const event = "app:user_selfieModal";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const zoomModalClick = createAsyncThunk(
  `${prefix} zoomModalClick`,
  async () => {
    const context = {};
    const event = "app:user_zoomModal";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const copyScript = createAsyncThunk(
  `${prefix} copyScript`,
  async () => {
    const context = {};
    const event = "app:editor_copyScript";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "copy_script",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const printScenes = createAsyncThunk(
  `${prefix} printScenes`,
  async () => {
    const context = {};
    const event = "app:editor_printScenes";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const clearScenes = createAsyncThunk(
  `${prefix} clearScenes`,
  async () => {
    const context = {};
    const event = "app:editor_clearScenes";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const changeView = createAsyncThunk(
  `${prefix} changeView`,
  async (context: { view: EditorView }) => {
    const event = "app:editor_changeView";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const userUpdatedCoupon = createAsyncThunk(
  `${prefix} userUpdatedCoupon`,
  async (context: { source: PaymentModalSource; coupon: string; plan: PlanEnum }) => {
    const event = "app:user_updatedCoupon";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);
export const userBuyAddonIntent = createAsyncThunk(
  `${prefix} userBuyAddonIntent`,
  async (context: { videoId: string }) => {
    const event = "app:user_buyAddOn";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const userAppliedCoupon = createAsyncThunk(
  `${prefix} userAppliedCoupon`,
  async (context: { coupon: string; plan: PlanToUpgrade }) => {
    const event = "app:user_appliedCoupon";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const createNewTag = createAsyncThunk(
  `${prefix} createNewTag`,
  async (context: { name: string }) => {
    const event = "app:app_createNewTag";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const createChannel = createAsyncThunk(
  `${prefix} createChannel`,
  async (context: { name: string }) => {
    const event = "app:app_createChannel";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const removeChannel = createAsyncThunk(
  `${prefix} removeChannel`,
  async (context: { name: string }) => {
    const event = "app:app_removeChannel";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const userClickedReplacedBanner = createAsyncThunk(
  `${prefix} userClickedReplacedBanner`,
  async (context: { bannerType: ReplacedBannerType }) => {
    const event = "app:app_bannerCTA";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const userUpgrade = createAsyncThunk(
  `${prefix} userUpgrade`,
  async (context: {
    source: PaymentModalSource;
    phase?: PaymentModalPhase;
    action: PaymentModalAction;
    plan: PlanEnum;
  }) => {
    const event = "app:user_upgrade";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const firstPayment = createAsyncThunk(
  `${prefix} firstPayment`,
  async () => {
    const event = "app:user_first_payment";
    gaDataLayerSend({}, event);
    segment.track(event, {});
  },
  thunkOptions
);

export const selectScene = createAsyncThunk(
  `${prefix} selectScene`,
  async (context: { selectedScene: SelectedScene; view: EditorView }) => {
    const event = "app:scene_select";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeLayout = createAsyncThunk(
  `${prefix} changeLayout`,
  async (context: { layout: { name: string; id: string } }) => {
    const event = "app:scene_changeLayout";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectImageFitType = createAsyncThunk(
  `${prefix} selectImageFitType`,
  async (context: { type?: FitOptions }) => {
    const event = "app:editor_imageFitType";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectImageFitPosition = createAsyncThunk(
  `${prefix} selectImageFitPosition`,
  async (context: { align?: Align }) => {
    const event = "app:editor_imageFitPosition";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectImageFitFilterType = createAsyncThunk(
  `${prefix} selectImageFitFilterType`,
  async (context: { filterType?: FilterType }) => {
    const event = "app:editor_imageFitFilterType";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const videoActions = createAsyncThunk(
  `${prefix} videoSettings`,
  async (context: {
    sceneNumber: number;
    draftId?: string;
    status?: string;
    action?: string;
    value?: string;
    origin: string;
  }) => {
    const event = "app:editor_videoActions";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const chooseImage = createAsyncThunk(
  `${prefix} chooseImage`,
  async (context: {
    selectedScene?: SelectedScene;
    draftId?: string;
    context?: string;
    origin: string;
  }) => {
    const event = "app:editor_media_select";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const removeVideoFromDraft = createAsyncThunk(
  `${prefix} removeVideoFromScene`,
  async (context: { draftId: string; context: string }) => {
    const event = "app:editor_media_video_remove";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const deleteMedia = createAsyncThunk(
  `${prefix} deleteMedia`,
  async (context: { selectedScene: SelectedScene; media: { url: string; title: string } }) => {
    const event = "app:mediaLibrary_deleteMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const previewMedia = createAsyncThunk(
  `${prefix} previewMedia`,
  async (context: { selectedScene: SelectedScene; media: { url: string } }) => {
    const event = "app:mediaLibrary_previewMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editMediaIntent = createAsyncThunk(
  `${prefix} editMediaIntent`,
  async (context: { selectedScene: SelectedScene; name: string }) => {
    const event = "app:mediaLibrary_editMediaIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editMedia = createAsyncThunk(
  `${prefix} editMedia`,
  async (context: { mediaId: string; name: string }) => {
    const event = "app:mediaLibrary_editMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const searchMedia = createAsyncThunk(
  `${prefix} searchMedia`,
  async (context: { selectedScene: SelectedScene; query: string }) => {
    const event = "app:mediaLibrary_searchMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const filterMedia = createAsyncThunk(
  `${prefix} filterMedia`,
  async (context: { selectedScene: SelectedScene; value: MediaType }) => {
    const event = "app:mediaLibrary_filterMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const sortMedia = createAsyncThunk(
  `${prefix} sortMedia`,
  async (context: { selectedScene: SelectedScene; value: MediaTypeSort }) => {
    const event = "app:mediaLibrary_sortMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeTabMediaLibrary = createAsyncThunk(
  `${prefix} changeTabMediaLibrary`,
  async (context: { selectedScene: SelectedScene; value: MediaLibraryTabs }) => {
    const event = "app:mediaLibrary_changeTab";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const deleteProfile = createAsyncThunk(
  `${prefix} deleteProfile`,
  async (context: { email: string; authId: string; plan: PlanEnum }) => {
    const event = "app:deleteProfile";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const closeMediaLibrary = createAsyncThunk(
  `${prefix} closeMediaLibrary`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:mediaLibrary_close";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeLogoIntent = createAsyncThunk(
  `${prefix} changeLogoIntent`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:scene_changeLogoIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_logo",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const changeLogoOpenModal = createAsyncThunk(
  `${prefix} changeLogoOpenModal`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:scene_changeLogoOpenModal";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_logo",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const removeLogo = createAsyncThunk(
  `${prefix} removeLogo`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:scene_removeLogo";
    gaDataLayerSend(context, event);

    segment.track(event, context);
  },
  thunkOptions
);

export const changeMedia = createAsyncThunk(
  `${prefix} changeMedia`,
  async (context: { selectedScene: SelectedScene; media: { url: string; type?: MediaType } }) => {
    const event = "app:scene_changeMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeTitle = createAsyncThunk(
  `${prefix} changeTitle`,
  async (context: { selectedScene: SelectedScene; title: { name: string; value: string } }) => {
    const event = "app:scene_changeTitle";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const changeSceneName = createAsyncThunk(
  `${prefix} changeSceneName`,
  async (context: { selectedScene: SelectedScene; value: string }) => {
    const event = "app:scene_changeName";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "draft",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export enum SceneActionOrigin {
  Scene = "Scene",
  NavigationBar = "NavigationBar"
}

export const addScene = createAsyncThunk(
  `${prefix} addScene`,
  async (context: { selectedScene: SelectedScene; action: SceneActionOrigin }) => {
    const event = "app:draft_addScene";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "add_scene",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const deleteScene = createAsyncThunk(
  `${prefix} deleteScene`,
  async (context: { selectedScene: SelectedScene; action: SceneActionOrigin }) => {
    const event = "app:draft_deleteScene";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "delete_scene",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const duplicateScene = createAsyncThunk(
  `${prefix} duplicateScene`,
  async (context: { selectedScene: SelectedScene; action: SceneActionOrigin }) => {
    const event = "app:draft_duplicateScene";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "duplicate_scene",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const pasteTextInScene = createAsyncThunk(
  `${prefix} pasteTextInScene`,
  async (context: {
    selectedScene: SelectedScene;
    transcriptText: string;
    transcriptLength: number;
  }) => {
    const event = "app:scene_transcriptImport";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const splitTextOfferAccepted = createAsyncThunk(
  `${prefix} splitTextOfferAccepted`,
  async (context: { sceneId: string; pastedText?: string }) => {
    const event = "app:scene_splitScene";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const removeInvalidChars = createAsyncThunk(
  `${prefix} removeInvalidChars`,
  async (context: { sceneId: string; transcript?: string }) => {
    const event = "app:scene_removeInvalidChars";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export enum SceneVoiceStatus {
  click = "click",
  upload = "upload",
  select = "select",
  startRecord = "startRecord",
  stopRecord = "stopRecord",
  remove = "remove",
  replace = "replace"
}

export const useVoice = createAsyncThunk(
  `${prefix} useVoice`,
  async (context: { selectedScene: SelectedScene; status: SceneVoiceStatus }) => {
    const event = "app:scene_useVoice";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export enum SelectColorType {
  edit_selected_color = "edit_selected_color",
  use_brand = "use_brand",
  change_by_palette_id = "change_by_palette_id"
}

export const selectFont = createAsyncThunk(
  `${prefix} selectFont`,
  async (context: { font: string; fontId: string }) => {
    const event = "app:draft_selectFont";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_font",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);
export const selectColor = createAsyncThunk(
  `${prefix} selectColor`,
  async (context: { palette: string; paletteId?: string; type?: SelectColorType }) => {
    const event = "app:draft_selectColor";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_style",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);
export const editColorIntent = createAsyncThunk(
  `${prefix} editColorIntent`,
  async (context: { palette: string; paletteId: string }) => {
    const event = "app:draft_editColorIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_style",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);
export enum EditPaletteSource {
  colorMap = "colorMap",
  colorRow = "colorRow",
  manual = "manual"
}
export const editPalette = createAsyncThunk(
  `${prefix} editPalette`,
  async (context: { source: EditPaletteSource }) => {
    const event = "app:draft_editPalette";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const editPaletteSelectColor = createAsyncThunk(
  `${prefix} editPaletteSelectColor`,
  async (context: { colorIndex: number; colorKey: string }) => {
    const event = "app:draft_editPaletteSelectColor";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "change_style",
      eventId: event,
      properties: {}
    });
  },
  thunkOptions
);

export const toggleMusic = createAsyncThunk(
  `${prefix} toggleMusic`,
  async (context: { value: boolean; draft: string }) => {
    const event = "app:draft_toggleMusic";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectBackgroundMusicTrack = createAsyncThunk(
  `${prefix} toggleMusic`,
  async (context: { track: BackgroundMusic; draft: string }) => {
    const event = "app:draft_selectMusic";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "add_music",
      eventId: event,
      properties: { draft: context.draft }
    });
  },
  thunkOptions
);

export const searchMusic = createAsyncThunk(
  `${prefix} searchMusic`,
  async (context: { value: string; isMood?: boolean }) => {
    const event = "app:draft_searchMusic";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const enrichSource = createAsyncThunk(
  `${prefix} enrichSource`,
  async (context: { url: string; type: string; email: string; hubspotToken: string }) => {
    const event = "app:user_enrichSource";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const screenRecord = createAsyncThunk(
  `${prefix} screenRecord`,
  async (context: {
    status:
      | "open"
      | "startRecord"
      | "record"
      | "cancelRecord"
      | "cancel"
      | "recordAgain"
      | "openTrim"
      | "useRecording";
  }) => {
    const event = "app:scene_screenRecord";
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...context
      }
    });
    segment.track(event, context);
  },
  thunkOptions
);

export const generateTitle = createAsyncThunk(
  `${prefix} generateTitle`,
  async (context: { selectedScene: SelectedScene; title: { name: string } }) => {
    const event = "app:scene_generateTitle";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const generateMedia = createAsyncThunk(
  `${prefix} generateMedia`,
  async (context: { selectedScene: SelectedScene }) => {
    const event = "app:scene_generateMedia";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const joinExperiment = createAsyncThunk(
  `${prefix} joinExperiment`,
  async (payload: JoinExperimentProps) => {
    gaDataLayerSend(
      {
        userId: payload.id,
        experimentName: payload.experimentName,
        data: payload.data || {}
      },
      "user_join_experiment"
    );
    segment.identify(
      payload.id,
      "",
      payload.email,
      getCurrentWorkspace(redux.store.getState())?.id || "",
      getCurrentWorkspace(redux.store.getState())?.current_team?.id || "",
      { [payload.experimentName]: "joined" },
      isMobile
    );

    const event = "exp:user_join";
    segment.track(event, { name: payload.experimentName, data: payload.data || {} });
  },
  thunkOptions
);

export const selectUseCase = createAsyncThunk(
  `${prefix} selectUseCase`,
  async (context: { useCase: UseCaseSegmentType }) => {
    const event = "app:user_selectUseCase";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectSegmentMinutes = createAsyncThunk(
  `${prefix} selectSegmentMinutes`,
  async (context: { minutes: string; linkedInProfileUrl?: string; howDidYouHear?: string }) => {
    const event = "app:user_selectSegmentMinutes";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectUserTypeSegment = createAsyncThunk(
  `${prefix} selectUserTypeSegment`,
  async (context: { userType: string }) => {
    const event = "app:user_selectUserTypeSegment";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const onBoarding = createAsyncThunk(
  `${prefix} onBoarding`,
  async (context: { status: string }) => {
    const event = "app:user_onBoarding";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const draftTranslationIntent = createAsyncThunk(
  `${prefix} draftTranslationIntent`,
  async (context: { language: string; draftId: string }) => {
    const event = "app:user_draftTranslationIntent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const track = createAsyncThunk(
  `${prefix} track`,
  async (context: {
    featureId: string;
    eventId: string;
    properties: Record<string, string>;
    key?: string;
  }) => {
    tracker(context);
  },
  thunkOptions
);

export const tracker = (context: {
  featureId: string;
  eventId: string;
  properties: Record<string, string | undefined>;
  key?: string;
}) => {
  const event = "app:track_event";
  context.key = "h1";
  gaDataLayerSend(context, event);
  segment.track(event, context);
};
export const onQuickVideoPreviewPlayerLoaded = createAsyncThunk(
  `${prefix} onQuickVideoPreviewPlayerLoaded`,
  async () => {
    const event = "app:draft_quickVideoPreviewPlayerLoaded";
    gaDataLayerSend({}, event);
    segment.track(event, {});
  },
  thunkOptions
);

export const onQuickVideoPreviewModalOpen = createAsyncThunk(
  `${prefix} onQuickVideoPreviewModalOpen`,
  async () => {
    const event = "app:draft_onQuickVideoPreviewModalOpen";
    gaDataLayerSend({}, event);
    segment.track(event, {});
  },
  thunkOptions
);

export const onVideoProtectionChange = createAsyncThunk(
  `${prefix} onVideoProtectionChange`,
  async (context: { videoId: string; protection: ProtectionType }) => {
    const event = "app:video_onVideoProtectionChange";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const errorPageShowed = createAsyncThunk(
  `${prefix} errorPageShowed`,
  async () => {
    const context = {};
    const event = "app:errors_errorPageShowed";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const selectedAvatarCreationType = createAsyncThunk(
  `${prefix} selectedAvatarCreationType`,
  async (context: { type: string }) => {
    const event = "app:user_avatarChooseCreationModal";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const avatarWebcamSessionProgress = createAsyncThunk(
  `${prefix} avatarWebcamSessionProgress`,
  async (context: {
    stage:
      | VirtualTwinSteps
      | "camera_settings"
      | "start_capture"
      | "finish_capture"
      | "approve_capture"
      | "redo_capture"
      | "start_capture_consent"
      | "finish_capture_consent";
  }) => {
    const event = "app:user_avatarWebcamSession";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const StartFromDocument = createAsyncThunk(
  `${prefix} StartFromDocument`,
  async (context: { status: "success" | "fail" }) => {
    const event = "app:user_startFromDocument";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "start_from_doc",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const StartFromUrl = createAsyncThunk(
  `${prefix} StartFromUrl`,
  async (context: { status: "success" | "fail" }) => {
    const event = "app:user_startFromUrl";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const StartFromPresentation = createAsyncThunk(
  `${prefix} StartFromPresentation`,
  async (context: { status: "success" | "fail" }) => {
    const event = "app:user_startFromPresentation";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const StartFromScript = createAsyncThunk(
  `${prefix} StartFromScript`,
  async (context: { status: "success" | "fail" }) => {
    const event = "app:user_startFromScript";
    gaDataLayerSend(context, event);
    segment.track(event, context);
    tracker({
      featureId: "start_from_script",
      eventId: event,
      properties: { ...context }
    });
  },
  thunkOptions
);

export const DraftGeneratorResult = createAsyncThunk(
  `${prefix} DraftGeneratorResult`,
  async (context: { status: "success" | "fail" }) => {
    const event = "app:user_draftGeneratorResult";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const segmentUseFor = createAsyncThunk(
  `${prefix} segmentUseFor`,
  async (context: { useFor: string }) => {
    const event = "app:user_segmentUseFor";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const segmentWorkTeam = createAsyncThunk(
  `${prefix} segmentWorkTeam`,
  async (context: { team: string }) => {
    const event = "app:user_segmentWorkTeam";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const segmentWorkContent = createAsyncThunk(
  `${prefix} segmentWorkContent`,
  async (context: { content: string | string[] }) => {
    const event = "app:user_segmentWorkContent";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const segmentSetupWorkspace = createAsyncThunk(
  `${prefix} segmentSetupWorkspace`,
  async (context: { workspaceName: string }) => {
    const event = "app:user_segmentSetupWorkspace";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
export const segmentCompanyWebsite = createAsyncThunk(
  `${prefix} segmentCompanyWebsite`,
  async (context: { companyWebsite: string }) => {
    const event = "app:user_segmentCompanyWebsite";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const segmentPhoneNumber = createAsyncThunk(
  `${prefix} segmentPhoneNumber`,
  async (context: { phoneNumber: string }) => {
    const event = "app:user_segmentPhoneNumber";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);

export const segmentHowDidYouHear = createAsyncThunk(
  `${prefix} segmentHowDidYouHear`,
  async (context: { howDidYouHear: string }) => {
    const event = "app:user_segmentHowDidYouHear";
    gaDataLayerSend(context, event);
    segment.track(event, context);
  },
  thunkOptions
);
