import { defineMessages } from "react-intl";

export const podcastMessages = defineMessages({
  title: {
    id: `podcast.title`,
    defaultMessage: "Podcast maker"
  },
  subtitle: {
    id: `podcast.subtitle`,
    defaultMessage: "Create podcast video from podcast audio"
  },
  generateBtn: {
    id: `podcast.generate-button`,
    defaultMessage: "Generate podcast"
  },
  cancelBtn: {
    id: `podcast.cancel-button`,
    defaultMessage: "Cancel"
  },
  backBtn: {
    id: `podcast.back-button`,
    defaultMessage: "Back"
  }
});
