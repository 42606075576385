import { createAsyncThunk } from "@reduxjs/toolkit";
import { scenesActions } from "app/store/slices/scenes.slice";
import { voicesActions } from "app/store/slices/voices.slice";
import { musicActions } from "app/store/slices/music.slice";
import { brandKitActions } from "app/store/slices/brandKit.slice";
import { charactersActions } from "app/store/slices/characters.slice";
import { templatesActions } from "app/store/slices/templates.slice";
import { userActions } from "app/store/slices/user.slice";
import { draftsGlobalSelectors, scenesGlobalSelectors } from "app/store/adapters/adapters";
import uiActions from "app/store/actions/ui.actions";
import { v4 as uuidv4 } from "uuid";

import {
  bulkSubmissions,
  changeCurrentDraftRecipe,
  cleanDraftsBulk,
  clearDraftScenes,
  connectDraftToDatastore,
  createApiExample,
  createDraft,
  createVariable,
  createVideoFromWorkflowCategories,
  createWorkflowSubmission,
  csvExport,
  deleteBulkDrafts,
  deleteDraft,
  detectDraftLanguage,
  draftGenerator,
  duplicateDraft,
  getDraft,
  getDraftHistory,
  getDrafts,
  getDraftScenes,
  getFilesFolders,
  getVideosByReqId,
  getWorkflow,
  getWorkflowCategories,
  getWorkflowLive,
  getWorkflowSubmissions,
  patchDraft,
  publishWorkflow,
  recoverDraftsBulk,
  revertDraft,
  revertWorkflow,
  runSubmission,
  saveAsRecipe,
  segmentToScene,
  summaryGenerator,
  summaryLeave,
  topicToDraft,
  updateDraftGlobalCharacter,
  updateVariable,
  updateWorkflowForm,
  workspaceSearch
} from "app/services/serviceV2Apis";
import {
  Draft,
  DraftExtended,
  draftGeneratorRequest,
  DraftRevertRequest,
  DraftVariable,
  DraftViewMode,
  FeatureFlag,
  FetchStatus,
  PatchOperation,
  RecoverBulkDraftRequest,
  Scene,
  SearchResults,
  SupportedTranslationLanguages,
  SynthesisMarkupLanguage,
  ThunkApi,
  VariableType,
  Video,
  VideoWizardV2Properties,
  WorkflowForm,
  WorkflowFormVariableResult,
  WorkflowResult,
  WorkflowSubmission,
  WorkflowVariableValue
} from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { DRAFT } from "app/components/common/aliases";
import { videosActions } from "app/store/slices/videos.slice";
import { SourceName } from "app/types/realsMe";
import { draftsActions } from "app/store/slices/drafts.slice";
import { recipesActions } from "app/store/slices/recipes.slice";
import { fontsActions } from "app/store/slices/fonts.slice";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";

const prefix = "[Drafts]";

const startWorkingOnDraft = createAsyncThunk<Draft, string, ThunkApi>(
  `${prefix} startWorkingOnDraft`,
  async (draftId, thunkAPI) => {
    thunkAPI.dispatch(scenesActions.getDraftsScenesRequest(draftId));
    const draft: Draft = await getDraft(draftId, true);
    thunkAPI.dispatch(
      templatesActions.getPalettesRequest({ draftId, templateId: draft.template_id as string })
    );
    thunkAPI.dispatch(fontsActions.getFontsRequest(draft.template_id));
    thunkAPI.dispatch(videosActions.getVideosRequest({ draftId }));
    thunkAPI.dispatch(templatesActions.getTemplateByIdRequest(draft.template_id as string));
    thunkAPI.dispatch(brandKitActions.getBrandKitRequest());
    if (draft.type === "workflow" && draft.data_store_id) {
      thunkAPI.dispatch(
        dataStoreActions.getImportDataRequest({
          dataStoreId: draft.data_store_id,
          includeContent: true
        })
      );
    }
    return draft;
  },
  thunkOptions
);

const refreshDraftAndScenes = createAsyncThunk<Draft, void, ThunkApi>(
  `${prefix} refreshDraftAndScenes`,
  async (_, thunkAPI) => {
    const draftId = thunkAPI.getState().drafts.currentDraft.id as string;
    thunkAPI.dispatch(scenesActions.getDraftsScenesRequest(draftId));
    const draft: Draft = await getDraft(draftId, true);

    return draft;
  },
  thunkOptions
);

const loadDraftResources = createAsyncThunk<void, void>(
  `${prefix} loadDraftResources`,
  async (arg, thunkAPI) => {
    thunkAPI.dispatch(charactersActions.getCharactersListRequest());
    thunkAPI.dispatch(voicesActions.getVoicesListRequest());
    thunkAPI.dispatch(musicActions.getBackgroundMusicListRequest());
  },
  thunkOptions
);

const patchDraftRequest = createAsyncThunk<
  any,
  { draftId: string; operations: PatchOperation[]; skipRePreview?: boolean },
  ThunkApi
>(
  `${prefix} patchDraftRequest`,
  async ({ draftId, operations, skipRePreview }, thunkApi) => {
    await patchDraft(draftId, operations);
    const {
      drafts: { currentDraft },
      user: { featureFlags }
    } = thunkApi.getState();
    const sceneIds = thunkApi.getState().scenes.ids as string[];
    const atLeastOneAttributeChange = operations.some(
      (operation) =>
        operation.path.startsWith("attributes.") ||
        operation.path.startsWith("palette_id") ||
        operation.path.startsWith("font_set_id")
    );
    if (
      !skipRePreview &&
      featureFlags[FeatureFlag.framePreviewFeature] &&
      currentDraft.id === draftId &&
      atLeastOneAttributeChange
    ) {
      //  checking if edit is by editor
      thunkApi.dispatch(scenesActions.scenePreviewRequest({ draftId, sceneIds }));
    }
  },
  thunkOptions
);

const patchRealsMeToEditorDraftRequest = createAsyncThunk<
  void,
  { draftId: string; operations: PatchOperation[] }
>(
  `${prefix} patchRealsMeToEditorDraftRequest`,
  async ({ draftId, operations }) => {
    await patchDraft(draftId, operations);
  },
  thunkOptions
);

const createDraftRequest = createAsyncThunk<any, { draft: Draft }, ThunkApi>(
  `${prefix} createDraftRequest`,
  async ({ draft }, thunkApi) => {
    const result = await createDraft(draft);
    if (thunkApi.getState().user.preferences.show_recommended) {
      thunkApi.dispatch(userActions.updateUserPreferencesRequest({ show_recommended: false }));
    }
    return result;
  },
  thunkOptions
);
const createFirstDraftRequest = createAsyncThunk<any, { draft: Draft }, ThunkApi>(
  `${prefix} createFirstDraftRequest`,
  async ({ draft }) => {
    const result = await createDraft(draft);
    return result;
  },
  thunkOptions
);

const getDraftsRequest = createAsyncThunk<
  Draft[],
  { limit: number; page: number; ids?: string[]; type?: "workflow"; viewMode?: DraftViewMode.me },
  ThunkApi
>(
  `${prefix} getDraftsRequest`,
  async ({ limit, page, ids, type, viewMode }, thunkApi) => {
    const result = await getDrafts(limit, page, ids, type, viewMode);
    const videos = result
      .filter((draft: Draft) => !!draft.last_video)
      .map((draft: Draft) => draft.last_video);
    thunkApi.dispatch(videosActions.setVideos(videos));
    const paramsVideoId = new URLSearchParams(document.location.search).get("video-id");
    if (paramsVideoId) {
      const isVideoFound = videos.map((v: Video) => v.req_id).includes(paramsVideoId);
      if (!isVideoFound) {
        thunkApi.dispatch(videosActions.getVideosByReqIdRequest(paramsVideoId));
      }
    }
    return result;
  },
  thunkOptions
);

const duplicateDraftsRequest = createAsyncThunk<
  Draft,
  { draftId: string; source?: SourceName },
  ThunkApi
>(
  `${prefix} duplicateDraftsRequest`,
  async ({ draftId, source }, thunkApi) => {
    const result = await duplicateDraft(draftId, source);
    const state = thunkApi.getState();
    thunkApi.dispatch(draftsActions.addDraftFirst(result));

    const originalDraft = draftsGlobalSelectors.selectById(state, draftId);
    if (originalDraft) {
      thunkApi.dispatch(
        uiActions.setNotifications([
          { message: `"${originalDraft.title}" ${DRAFT.toLowerCase()} was duplicated` }
        ])
      );
    }
    return result;
  },
  thunkOptions
);

const translateDraftRequest = createAsyncThunk<
  { callbackId: string; originDraft: string },
  { draftId: string; translateTo?: SupportedTranslationLanguages },
  ThunkApi
>(
  `${prefix} translateDraftRequest`,
  async ({ draftId, translateTo }) => {
    const callbackResponse = await duplicateDraft(draftId, undefined, translateTo);
    return { callbackId: callbackResponse.callback_id, originDraft: draftId };
  },
  thunkOptions
);

const workspaceSearchRequest = createAsyncThunk<SearchResults, { searchValue: string }, ThunkApi>(
  `${prefix} workspaceSearchRequest`,
  async ({ searchValue }) => {
    const response = await workspaceSearch(searchValue);
    return response;
  },
  thunkOptions
);

const detectDraftLanguageRequest = createAsyncThunk<any, { draftId: string }, ThunkApi>(
  `${prefix} detectDraftLanguageRequest`,
  async ({ draftId }) => {
    const lang = await detectDraftLanguage(draftId);
    return lang;
  },
  thunkOptions
);

const updateDraftGlobalCharacterRequest = createAsyncThunk<
  Draft,
  { globalCharacter: boolean },
  ThunkApi
>(
  `${prefix} updateDraftGlobalCharacterRequest`,
  async ({ globalCharacter }, thunkApi) => {
    // cinematic template is not supported.

    const {
      drafts: { currentDraft },
      scenes: { selectedSceneId }
    } = thunkApi.getState();
    const draftId = currentDraft.id as string;
    if (globalCharacter) {
      const currentSelectedScene = scenesGlobalSelectors.selectById(
        thunkApi.getState(),
        selectedSceneId as string
      );
      const voiceId = currentSelectedScene?.attributes?.voice?.voice?.voice_id as string;
      const voiceAdjustments = currentSelectedScene?.attributes?.voice?.voice?.voice_adjustments;
      const characterId = currentSelectedScene?.attributes?.character?.character
        ?.character_id as string;
      if (
        currentDraft &&
        currentDraft.attributes?.voice?.voice &&
        currentDraft.attributes?.character?.character
      ) {
        thunkApi.dispatch(
          draftsActions.updateCurrentDraft({
            ...currentDraft,
            attributes: {
              ...currentDraft.attributes,
              voice: {
                voice: { voice_id: voiceId, voice_adjustments: voiceAdjustments }
              },
              character: {
                character: {
                  character_id: characterId
                }
              }
            }
          })
        );
      }
    } else {
      thunkApi.dispatch(
        scenesActions.setScenesCharacterAndVoiceAsGlobal({
          voiceId: currentDraft.attributes?.voice?.voice?.voice_id as string,
          voiceAdjustment: currentDraft.attributes?.voice?.voice?.voice_adjustments,
          characterId: currentDraft.attributes?.character?.character?.character_id as string
        })
      );
    }
    const res: Draft = await updateDraftGlobalCharacter(globalCharacter, draftId);

    if (!globalCharacter) {
      thunkApi.dispatch(
        scenesActions.setScenesCharacterAndVoiceAsGlobal({
          voiceId: (res.attributes?.voice?.voice?.voice_id ||
            currentDraft.attributes?.voice?.voice?.voice_id) as string,
          voiceAdjustment:
            res.attributes?.voice?.voice?.voice_adjustments ||
            currentDraft.attributes?.voice?.voice?.voice_adjustments,
          characterId: (res.attributes?.character?.character?.character_id ||
            currentDraft.attributes?.character?.character?.character_id) as string
        })
      );
    }
    if (globalCharacter) {
      const currentSelectedScene = scenesGlobalSelectors.selectById(
        thunkApi.getState(),
        selectedSceneId as string
      );
      const voiceId = currentSelectedScene?.attributes?.voice?.voice?.voice_id as string;
      const voiceAdjustments = currentSelectedScene?.attributes?.voice?.voice?.voice_adjustments;
      const characterId = currentSelectedScene?.attributes?.character?.character
        ?.character_id as string;
      const operations = [];
      operations.push({
        op: "replace",
        path: "attributes.voice.voice",
        value: {
          voice_id: voiceId,
          voice_adjustments: voiceAdjustments
        }
      } as PatchOperation);
      operations.push({
        op: "replace",
        path: "attributes.character.character.character_id",
        value: characterId
      } as PatchOperation);
      thunkApi.dispatch(draftsActions.patchDraftRequest({ draftId, operations }));
    }

    return res;
  },
  thunkOptions
);

const deleteDraftRequest = createAsyncThunk<any, string>(
  `${prefix} deleteDraftRequest`,
  async (draftId: string) => {
    await deleteDraft(draftId);
  },
  thunkOptions
);

const getDraftVersionRequest = createAsyncThunk<any, { draftId: string; version: string }>(
  `${prefix} getDraftVersionRequest`,
  async ({ draftId, version }) => {
    const result = await getDraftHistory(draftId, true, version);
    return result;
  },
  thunkOptions
);

const getDraftHistoryRequest = createAsyncThunk<any, { draftId: string }>(
  `${prefix} getDraftHistoryRequest`,
  async ({ draftId }) => {
    const result = await getDraftHistory(draftId, false);
    return result;
  },
  thunkOptions
);

const createApiVideoExampleRequest = createAsyncThunk<
  { curl: string; json_request: string },
  {
    draftId?: string;
    workflowId?: string;
    type: "create" | "override" | "create_video_from_workflow";
  }
>(
  `${prefix} createApiVideoExampleRequest`,
  async ({ draftId, type, workflowId }) => {
    const result = await createApiExample(type, draftId, workflowId);
    return result;
  },
  thunkOptions
);

const createNewRealsMeDraftRequest = createAsyncThunk<Draft, void>(
  `${prefix} createNewRealsMeDraftRequest`,
  async (_, thunkApi) => {
    const draft: Draft = {
      title: "new draft",
      type: "realsme",
      tags: ["#reals_me"],
      source: SourceName.realsme
    };
    const result = await createDraft(draft);
    const draftScenes = await getDraftScenes(result?.id as string);

    thunkApi.dispatch(scenesActions.addOneScene(draftScenes[0]));
    thunkApi.dispatch(draftsActions.addDraftFirst(result));

    return result;
  },
  thunkOptions
);

const clearScenesRequest = createAsyncThunk<void, string, ThunkApi>(
  `${prefix} clearScenesRequest`,
  async (draftId, thunkApi) => {
    const state = thunkApi.getState();
    const draftScenes = scenesGlobalSelectors.selectAll(state);
    thunkApi.dispatch(scenesActions.setSelectedSceneId(draftScenes[0].id));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    draftScenes!.forEach((scene, index) => {
      thunkApi.dispatch(scenesActions.updateSceneTranscript({ sceneId: scene.id, value: "" }));
      if (index !== 0) {
        thunkApi.dispatch(scenesActions.removeScene({ sceneId: scene.id }));
      }
    });
    await clearDraftScenes(draftId);
  },
  thunkOptions
);

const saveAsRecipeRequest = createAsyncThunk<
  any,
  { owner: string; draftId: string; title: string; description?: string },
  ThunkApi
>(
  `${prefix} saveAsRecipeRequest`,
  async ({ owner, draftId, title, description }, thunkApi) => {
    const result = await saveAsRecipe(draftId, title, owner, description);
    thunkApi.dispatch(recipesActions.setUseCache(false));

    return result;
  },
  thunkOptions
);

const changeCurrentDraftRecipeRequest = createAsyncThunk<
  Draft,
  { draftId: string; recipeId: string },
  ThunkApi
>(`${prefix} changeCurrentDraftRecipeRequest`, async ({ draftId, recipeId }, thunkAPI) => {
  const result = await changeCurrentDraftRecipe(draftId, recipeId);
  const {
    user: { featureFlags }
  } = thunkAPI.getState();
  thunkAPI.dispatch(scenesActions.setAllScenes(result.scenes));
  thunkAPI.dispatch(templatesActions.replaceCurrentTemplate(result.template));
  thunkAPI.dispatch(
    templatesActions.getPalettesRequest({
      draftId,
      templateId: result.template_id as string
    })
  );
  if (featureFlags[FeatureFlag.framePreviewFeature]) {
    const scenesIdsForPreview = result.scenes.map((scene: Scene) => scene.id);

    if (scenesIdsForPreview.length) {
      thunkAPI.dispatch(
        scenesActions.scenePreviewRequest({ draftId, sceneIds: scenesIdsForPreview })
      );
    }
  }

  const updatedDraft = { ...result };
  // @ts-ignore handels The operand of a 'delete' operator must be optional.
  delete updatedDraft.scenes;
  // @ts-ignore handels The operand of a 'delete' operator must be optional.
  delete updatedDraft.template;

  return result;
});

export const topicToDraftRequest = createAsyncThunk<
  void,
  {
    type: "populate_draft_with_topic" | "create_draft_from_topic";
    title?: string;
    promptValue: string;
    numberOfScenes: number;
    orderId: string;
    bump?: boolean;
    draftId?: string;
    targetPlatform?: string;
  },
  ThunkApi
>(
  `${prefix} topicToDraftRequest`,
  async ({ type, orderId, title, draftId, promptValue, numberOfScenes, bump, targetPlatform }) => {
    await topicToDraft(
      type,
      promptValue,
      numberOfScenes,
      orderId,
      title,
      bump,
      draftId,
      targetPlatform
    );
  },
  thunkOptions
);

const segmentToScenesRequest = createAsyncThunk<
  { orderId: string },
  {
    sceneId: string;
  },
  ThunkApi
>(
  `${prefix} segmentToScenesRequest`,
  async ({ sceneId }) => {
    const result = await segmentToScene({ sceneId, orderId: uuidv4() });
    const format = await result.data;
    return { orderId: format.order_id };
  },
  thunkOptions
);

const revertDraftRequest = createAsyncThunk<
  DraftExtended,
  { draftId: string; body: DraftRevertRequest },
  ThunkApi
>(
  `${prefix} revertDraftRequest`,
  async ({ draftId, body }, thunkApi) => {
    const result = await revertDraft(draftId, body);
    const { scenes } = result;

    if (scenes.length > 0) {
      const sceneIds = scenes.map(({ id }: Scene) => id);
      thunkApi.dispatch(scenesActions.setScenesAndSelectId({ scenes, id: sceneIds[0] }));
      thunkApi.dispatch(scenesActions.scenePreviewRequest({ draftId, sceneIds }));
    }

    return result;
  },
  thunkOptions
);
const handleVideoWizardResult = createAsyncThunk<
  undefined | { draft_id?: string; order_id: string; status: FetchStatus },
  { draft_id?: string; order_id: string; status: FetchStatus },
  ThunkApi
>(
  `${prefix} handleVideoWizardResult`,
  async ({ draft_id, order_id, status }, thunkApi) => {
    const {
      drafts: { topicToDraftOrderId }
    } = thunkApi.getState();
    if (order_id === topicToDraftOrderId) {
      return { draft_id, order_id, status };
    }
  },
  thunkOptions
);

const handleSegmentToScenesResults = createAsyncThunk<
  undefined | { sceneId?: string; orderId: string; status: FetchStatus },
  { sceneId?: string; orderId: string; status: FetchStatus },
  ThunkApi
>(
  `${prefix} handleSegmentToScenesResults`,
  async ({ sceneId, orderId, status }, thunkApi) => {
    const {
      drafts: { segmentToScenesOrderId }
    } = thunkApi.getState();
    if (orderId === segmentToScenesOrderId) {
      return { sceneId, orderId, status };
    }
  },
  thunkOptions
);

const handleDraftTranslationResults = createAsyncThunk<
  undefined | { draftId?: string; orderId: string; status: FetchStatus },
  { draftId?: string; orderId: string; status: FetchStatus },
  ThunkApi
>(
  `${prefix} handleDraftTranslationResults`,
  async ({ draftId, orderId, status }, thunkApi) => {
    const {
      drafts: { draftTranslationOrderId }
    } = thunkApi.getState();
    // Only filters the wanted translations reasults
    if (draftTranslationOrderId === orderId) {
      return { draftId, orderId, status };
    }
    return undefined;
  },
  thunkOptions
);

const getWorkflowCategoriesRequest = createAsyncThunk<any, void>(
  `${prefix} getWorkflowCategoriesRequest`,
  async () => {
    const result = await getWorkflowCategories();
    return result;
  },
  thunkOptions
);

const createVideoFromWorkflowCategoriesRequest = createAsyncThunk<
  any,
  { properties: Partial<VideoWizardV2Properties>; orderId: string }
>(
  `${prefix} createVideoFromWorkflowCategoriesRequest`,
  async ({ properties, orderId }) => {
    const result = await createVideoFromWorkflowCategories(properties, orderId);
    return result;
  },
  thunkOptions
);

const createDraftGenerator = createAsyncThunk<void, draftGeneratorRequest, ThunkApi>(
  `${prefix} createDraftGenerator`,
  async (splitRequest) => {
    await draftGenerator(splitRequest);
  },
  thunkOptions
);

const getMeetingsDraftsRequest = createAsyncThunk<Draft, { draftId?: string }>(
  `${prefix} getMeetingsDraftsRequest`,
  async ({ draftId }) => {
    if (draftId) {
      const res = await getDrafts(1, 1, [draftId]);
      return res[0];
    }
    const res = await getFilesFolders(undefined, "Meetings/");
    const result = res.data;
    const draft = result?.[0]?.data;
    if (draft) {
      const res = await getDrafts(1, 1, [draft.id]);
      return res[0];
    }
    return null;
  },
  thunkOptions
);

const summaryRequest = createAsyncThunk<
  { id?: string },
  { url: string; type: "audio_recorded" | "zoom_meeting"; orderId: string }
>(
  `${prefix} summaryRequest`,
  async ({ type, url, orderId }) => {
    return await summaryGenerator(type, url, orderId);
  },
  thunkOptions
);

const summaryLeaveRequest = createAsyncThunk<void, { id: string }>(
  `${prefix} summaryLeaveRequest`,
  async ({ id }) => {
    return await summaryLeave(id);
  },
  thunkOptions
);
const addVariableRequest = createAsyncThunk<
  DraftVariable,
  { draftId: string; variable: Omit<DraftVariable, "id"> }
>(
  `${prefix} addVariableRequest`,
  async ({ draftId, variable }) => {
    const result = await createVariable(draftId, variable);
    return result;
  },
  thunkOptions
);

const updateVariableRequest = createAsyncThunk<
  DraftVariable,
  { draftId: string; variableId: string; variable: Partial<DraftVariable> }
>(
  `${prefix} updateVariableRequest`,
  async ({ draftId, variableId, variable }) => {
    const result = await updateVariable(draftId, variableId, variable);
    return result;
  },
  thunkOptions
);

const updateWorkflowFormRequest = createAsyncThunk<
  DraftVariable,
  { workflowId: string; form: Partial<WorkflowForm> }
>(
  `${prefix} updateWorkflowFormRequest`,
  async ({ workflowId, form }) => {
    const result = await updateWorkflowForm(workflowId, form);
    return result;
  },
  thunkOptions
);
const publishWorkflowRequest = createAsyncThunk<void, { workflowId: string }, ThunkApi>(
  `${prefix} publishWorkflowRequest`,
  async ({ workflowId }, thunkAPI) => {
    thunkAPI.dispatch(dataStoreActions.cleanDataStore());
    await publishWorkflow(workflowId);
  },
  thunkOptions
);

const getLiveWorkflowRequest = createAsyncThunk<DraftExtended, { workflowId: string }>(
  `${prefix} getLiveWorkflowRequest`,
  async ({ workflowId }, thunkAPI) => {
    thunkAPI.dispatch(scenesActions.saveScenesToTemp());
    thunkAPI.dispatch(draftsActions.saveCurrentDraftToTemp());
    thunkAPI.dispatch(dataStoreActions.saveCurrentCsvTable());
    const result = await getWorkflowLive(workflowId);
    thunkAPI.dispatch(scenesActions.setAllScenes(result.scenes));
    thunkAPI.dispatch(uiActionsV2.setShowEditorDrawer(false));
    return result;
  },
  thunkOptions
);
const createWorkflowSubmissionRequest = createAsyncThunk<
  WorkflowSubmission,
  {
    workflowId: string;
    variables?: WorkflowVariableValue[];
    action: "create_draft" | "create_video";
    datastore_content_id?: string;
  }
>(
  `${prefix} createWorkflowSubmissionRequest`,
  async ({ workflowId, variables, action }, thunkAPI) => {
    const result = await createWorkflowSubmission(workflowId, variables, action);

    if (result.video) {
      thunkAPI.dispatch(videosActions.addVideoToFirst(result.video));
    }
    return result;
  },
  thunkOptions
);
const getWorkflowRequest = createAsyncThunk<WorkflowResult, { workflowId: string }>(
  `${prefix} getWorkflowRequest`,
  async ({ workflowId }) => {
    const result = await getWorkflow(workflowId);
    return result;
  },
  thunkOptions
);

const getWorkflowSubmissionsRequest = createAsyncThunk<
  WorkflowSubmission[],
  { workflowId: string }
>(
  `${prefix} getWorkflowSubmissionsRequest`,
  async ({ workflowId }, thunkAPI) => {
    const result = await getWorkflowSubmissions(workflowId);
    const videos = result.map((submission) => submission.video).filter((video) => !!video);
    thunkAPI.dispatch(videosActions.setVideos(videos as Video[]));

    return result;
  },
  thunkOptions
);

const revertWorkflowRequest = createAsyncThunk<DraftExtended, { workflowId: string }, ThunkApi>(
  `${prefix} revertWorkflowRequest`,
  async ({ workflowId }, thunkAPI) => {
    const result = await revertWorkflow(workflowId);
    const { scenes } = result;
    const draftId = result.id as string;
    // todo what about the template ? selected template
    if (scenes.length > 0) {
      const sceneIds = scenes.map(({ id }: Scene) => id);
      thunkAPI.dispatch(scenesActions.setScenesAndSelectId({ scenes, id: sceneIds[0] }));
      // todo need to check if original revert work, because seems there is no call for updateOutsideChangeSynthesisMarkupLanguage @tidhar

      thunkAPI.dispatch(scenesActions.scenePreviewRequest({ draftId, sceneIds }));

      const scenesSmlUpdate = scenes.reduce(
        (acc: Record<string, SynthesisMarkupLanguage[]>, scene: Scene) => {
          if (scene.attributes.text?.transcript?.synthesis_markup_language?.nodes) {
            acc[scene.id] = scene.attributes.text?.transcript?.synthesis_markup_language?.nodes;
          }
          return acc;
        },
        {}
      );

      thunkAPI.dispatch(scenesActions.updateOutsideChangeSynthesisMarkupLanguage(scenesSmlUpdate));
    }
    thunkAPI.dispatch(templatesActions.replaceCurrentTemplate(result.template));

    return result;
  },
  thunkOptions
);
const runSubmissionRequest = createAsyncThunk<
  { draft?: Draft },
  { workflowId: string; submissionId: string; type: "create_draft" | "create_video" },
  ThunkApi
>(
  `${prefix} runSubmissionRequest`,
  async ({ workflowId, submissionId, type }) => {
    const result = await runSubmission(workflowId, submissionId, type);

    return result;
  },
  thunkOptions
);

const connectDraftToDatastoreRequest = createAsyncThunk<
  { variables_created: WorkflowFormVariableResult[] },
  { dataStoreId: string; draftId: string },
  ThunkApi
>(
  `${prefix} connectDraftToDatastoreRequest`,
  async ({ dataStoreId, draftId }) => {
    if (draftId) {
      const res = await connectDraftToDatastore(dataStoreId, draftId);
      return {
        variables_created: res.variables_created.map((variable: WorkflowFormVariableResult) => ({
          ...variable,
          type: VariableType.text
        }))
      };
    }
    return { variables_created: [] };
  },
  thunkOptions
);

const bulkSubmissionsRequest = createAsyncThunk<
  void,
  { dataStoreContentIds: string[]; dataStoreId: string; workflowId: string },
  ThunkApi
>(
  `${prefix} bulkSubmissionsRequest`,
  async ({ dataStoreContentIds, dataStoreId, workflowId }, thunkAPI) => {
    if (workflowId) {
      const res = await bulkSubmissions(dataStoreContentIds, workflowId, dataStoreId);
      let error = "";
      const dataStoreContentIdsErrors: Record<string, boolean> = {};
      const videos = res.flatMap((response) => {
        if (response.success) {
          dataStoreContentIdsErrors[response.datastore_content_id] = false;
          return [response.video as Video];
        } else {
          dataStoreContentIdsErrors[response.datastore_content_id] = true;
          if (!error && response.error && response.detail) {
            error = response.detail;
          }
          return [];
        }
      });
      if (videos.length > 0) {
        thunkAPI.dispatch(videosActions.addMultipleVideos(videos));
      }
      if (error) {
        // @ts-ignore handels userUI store typing
        const { intl } = thunkAPI.getState().userUi;
        const insufficientCreditsError = error.indexOf("insufficient credits") > -1;
        if (insufficientCreditsError) {
          thunkAPI.dispatch(
            uiActions.setErrors({
              general: buildGeneralError(`Insufficient workflow credits`, intl)
            })
          );
        } else {
          uiActions.setErrors({
            general: buildGeneralError(error, intl)
          });
        }
      }
      thunkAPI.dispatch(dataStoreActions.updateImportDataErrorsResult(dataStoreContentIdsErrors));
    }
  },
  thunkOptions
);

const csvExportRequest = createAsyncThunk<
  void,
  { dataStoreContentIds: string[]; dataStoreId: string; workflowId: string },
  ThunkApi
>(
  `${prefix} csvExportRequest`,
  async ({ workflowId, dataStoreContentIds, dataStoreId }) => {
    const result = await csvExport(dataStoreContentIds, workflowId, dataStoreId);
    const blob = new Blob([result], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  },
  thunkOptions
);
const recoverDraftRequest = createAsyncThunk<void, RecoverBulkDraftRequest[]>(
  `${prefix} recoverDraftRequest`,
  async (recoverDrafts, thunkAPI) => {
    thunkAPI.dispatch(draftsActions.cleanSelectedDraftIds());
    await recoverDraftsBulk(recoverDrafts);
  },
  thunkOptions
);

const cleanDraftRequest = createAsyncThunk<void, string[]>(
  `${prefix} cleanDraftRequest`,
  async (draftsIds, thunkAPI) => {
    thunkAPI.dispatch(draftsActions.cleanSelectedDraftIds());
    const cleanedDrafts = draftsIds.map((id: string) => ({ item_id: id }));
    await cleanDraftsBulk(cleanedDrafts);
  },
  thunkOptions
);

const deleteBulkDraftsRequest = createAsyncThunk<void, { draftsIds: string[] }, ThunkApi>(
  `${prefix} deleteBulkDraftsRequest`,
  async ({ draftsIds }) => {
    await deleteBulkDrafts(draftsIds);
  },
  thunkOptions
);

const getDraftAndVideosByDraftIdRequest = createAsyncThunk<
  Draft,
  { draft_id: string; req_id: string },
  ThunkApi
>(
  `${prefix} getDraftAndVideosByDraftIdRequest`,
  async ({ draft_id, req_id }, thunkApi) => {
    const draft: Draft = await getDraft(draft_id, true);
    const video = await getVideosByReqId(req_id);
    draft.last_video = video;
    thunkApi.dispatch(videosActions.addVideoToFirst(video));

    return draft;
  },
  thunkOptions
);

export default {
  startWorkingOnDraft,
  createDraftRequest,
  patchDraftRequest,
  patchRealsMeToEditorDraftRequest,
  getDraftsRequest,
  duplicateDraftsRequest,
  deleteDraftRequest,
  loadDraftResources,
  getDraftVersionRequest,
  getDraftHistoryRequest,
  createApiVideoExampleRequest,
  createNewRealsMeDraftRequest,
  saveAsRecipeRequest,
  changeCurrentDraftRecipeRequest,
  topicToDraftRequest,
  clearScenesRequest,
  revertDraftRequest,
  refreshDraftAndScenes,
  handleVideoWizardResult,
  handleSegmentToScenesResults,
  segmentToScenesRequest,
  createFirstDraftRequest,
  translateDraftRequest,
  workspaceSearchRequest,
  detectDraftLanguageRequest,
  updateDraftGlobalCharacterRequest,
  getWorkflowCategoriesRequest,
  createVideoFromWorkflowCategoriesRequest,
  handleDraftTranslationResults,
  createDraftGenerator,
  getMeetingsDraftsRequest,
  summaryRequest,
  addVariableRequest,
  updateVariableRequest,
  updateWorkflowFormRequest,
  publishWorkflowRequest,
  getLiveWorkflowRequest,
  getWorkflowRequest,
  createWorkflowSubmissionRequest,
  getWorkflowSubmissionsRequest,
  revertWorkflowRequest,
  runSubmissionRequest,
  summaryLeaveRequest,
  connectDraftToDatastoreRequest,
  bulkSubmissionsRequest,
  csvExportRequest,
  deleteBulkDraftsRequest,
  recoverDraftRequest,
  cleanDraftRequest,
  getDraftAndVideosByDraftIdRequest
};
