import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { podcastMessages } from "app/pages/HomePage/PodcastModal/messages";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { ReactNode, useEffect, useState } from "react";
import { draftGeneratorRequest, DraftGeneratorType, MimeType } from "app/types";
import { Button } from "@nextui-org/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import ButtonBox from "app/components/common/ButtonBox";
import { transcriptAudioUploaderMessages } from "app/components/editor/scene/transcriptAudioUploader/messages";
import UploadFileContent from "app/components/common/UploadFileContent";
import { VIEW_MODE } from "app/components/editor/scene/transcriptAudioUploader/TranscriptAudioModalContent";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import FilePlayer from "app/components/common/FilePlayer";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import { v4 as uuidv4 } from "uuid";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DocumentLoadingCreationModal from "app/components/editor/documents/DocumentLoadingCreationModal";
import { fetchingStatus } from "app/utils/helpers";

interface PodcastModalProps {
  visible: boolean;
  onClose: () => void;
}

const PodcastModal = ({ visible, onClose }: PodcastModalProps) => {
  const [createVideoLoadingModal, setCreateVideoLoadingModal] = useState(false);
  const [currentView, setCurrentView] = useState<VIEW_MODE>(VIEW_MODE.main);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { file, reset, importLoading, executeImport, onSelectFile, fileStoreResult } =
    useFileUploadFilestack();
  const { playAudio, stopAudio, isPlaying } = useAudioPlayer(file);

  const draftGeneratorOrderId = useAppSelector((state) => state.drafts.createDraftGeneratorOrderId);
  const createDraftGeneratorStatus = useAppSelector(
    (state) => state.drafts.createDraftGeneratorStatus
  );

  useEffect(() => {
    if (visible && createDraftGeneratorStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      setCreateVideoLoadingModal(false);
      onClose();
      cleanFile();
    }
    if (visible && createDraftGeneratorStatus === fetchingStatus.failed) {
      onClose();
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      setCreateVideoLoadingModal(false);
      cleanFile();
    }
  }, [createDraftGeneratorStatus]);

  useEffect(() => {
    if (visible && fileStoreResult) {
      setCreateVideoLoadingModal(true);
      const draftGeneratorRequest: draftGeneratorRequest = {
        title: fileStoreResult.name,
        order_id: uuidv4().toString(),
        type: DraftGeneratorType.Podcast,
        podcast_url: fileStoreResult.url
      };
      dispatch(draftsActions.createDraftGenerator(draftGeneratorRequest));
    }
  }, [fileStoreResult]);

  const onChooseFile = (selectedFile: File) => {
    onSelectFile(selectedFile);
    setCurrentView(VIEW_MODE.finish);
  };

  const cleanFile = () => {
    reset();
    setCurrentView(VIEW_MODE.main);
  };

  if (visible && createVideoLoadingModal) {
    return <DocumentLoadingCreationModal trayKey={draftGeneratorOrderId} startFromType="podcast" />;
  }

  return (
    <StyledModal
      width="408px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "500px" }}
      onCancel={onClose}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="24px 16px 12px"
      $gap="21px"
    >
      <H1_FlexColumn>
        <H1_TextSubtitle color={theme.gray10}>
          {formatMessage(podcastMessages.title)}
        </H1_TextSubtitle>
        <H1_TextSmall color={theme.gray6}>{formatMessage(podcastMessages.subtitle)}</H1_TextSmall>
      </H1_FlexColumn>
      <ConditionalRender condition={currentView === VIEW_MODE.main}>
        <UploadFileContent
          disabled={importLoading}
          fileTypes={[MimeType.mp4, MimeType.wav]}
          onSelectFile={(files) => onChooseFile(files[0])}
          height="100%"
        >
          <ButtonBox
            iconColor={theme.blue2}
            height="100%"
            icon={<i className="fa-solid fa-file-music" />}
            title={
              formatMessage(transcriptAudioUploaderMessages.uploadFileButtonTitle, {
                br: <br />
              }) as ReactNode
            }
            description={formatMessage(transcriptAudioUploaderMessages.uploadFileButtonDescription)}
          />
        </UploadFileContent>
      </ConditionalRender>
      <ConditionalRender condition={currentView === VIEW_MODE.finish}>
        <H1_FlexRow height="100%" align="center">
          <FilePlayer
            fileName={file?.name}
            fileSize={file?.size}
            playing={isPlaying}
            onPlay={playAudio}
            onStop={stopAudio}
          />
        </H1_FlexRow>
      </ConditionalRender>
      <H1_FlexRow
        gap="10px"
        align="center"
        justify={currentView === VIEW_MODE.finish ? "space-between" : "flex-end"}
      >
        <ConditionalRender condition={currentView === VIEW_MODE.finish}>
          <Button variant="light" onClick={cleanFile}>
            {formatMessage(podcastMessages.backBtn)}
          </Button>
        </ConditionalRender>
        <H1_FlexRow gap="10px" align="center" justify="flex-end">
          <Button variant="bordered" onClick={onClose}>
            {formatMessage(podcastMessages.cancelBtn)}
          </Button>
          <Button
            color="primary"
            onClick={executeImport}
            isDisabled={!file}
            isLoading={importLoading}
          >
            {formatMessage(podcastMessages.generateBtn)}
          </Button>
        </H1_FlexRow>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default PodcastModal;
