import { FetchStatus, CGNType } from "app/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/cgn.thunk";
import { fetchingStatus } from "app/utils/helpers";

interface CGNSliceState {
  playback_id: string;
  type: CGNType;
  getCGNStatus: FetchStatus;
}

const INITIAL_STATE: CGNSliceState = {
  playback_id: "",
  type: CGNType.asset,
  getCGNStatus: fetchingStatus.idle
};

export const cgnSlice = createSlice({
  name: "CGN",
  initialState: INITIAL_STATE,
  reducers: {
    setCGNProperties(state, action: PayloadAction<{ playback_id: string; type: CGNType }>) {
      const { playback_id, type } = action.payload;
      state.playback_id = playback_id;
      state.type = type;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getCGNRequest.fulfilled, (state, action) => {
      const { playback_id, type } = action.payload;
      state.playback_id = playback_id;
      state.type = type;
      state.getCGNStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.pending, (state) => {
      state.getCGNStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.rejected, (state) => {
      state.getCGNStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default cgnSlice.reducer;

export const cgnActions = {
  getCGNRequest: asyncThunks.getCGNRequest,
  ...cgnSlice.actions
};
